import axios from 'axios'
import qs from 'qs'

export default {
    state: {
        service: [],
        services: [],
        _service: [],
    },
    getters: {
        getService: state => state.service,
        getServices: state => state.services,
        _getService: state => state._service,
    },
    actions: {
        getServices(ctx, data) {
            return new Promise((resolve, reject) => {
                axios.get('services/get-list?' + qs.stringify(data)).then(response => {
                    resolve(response);
                    ctx.commit('setServiceList', response);
                }).catch(error => {
                    reject(error);
                });
            });
        },
        getService(ctx, data) {
            return new Promise((resolve, reject) => {
                axios.get('services/get?' + qs.stringify(data)).then(response => {
                    resolve(response);
                    ctx.commit('setService', response);
                }).catch(error => {
                    reject(error);
                });
            });
        },
        setAboutService(ctx, data) {
            ctx.commit('setAboutService', data)
        }
    },
    mutations: {
        setService(state, data) {
            state.service = data.data.data.service;
        },
        setServiceList(state, data) {
            state.services = data.data.data.services;
        },
        setAboutService(state, data) {
            state._service = data;
        }
    }
}
