import axios from 'axios'
import * as core from '@/assets/js/core'

export default {
    state: {
        slides: [],
        banners: [],
        recommended: [],
    },
    getters: {
        getSlides: state => state.slides,
        getBanners: state => state.banners,
        getRecommended: state => state.recommended
    },
    actions: {
        getIndexInfo(ctx) {
            return new Promise((resolve, reject) => {
                axios.get('/pages/main').then(response => {
                    ctx.commit('updateIndexInfo', response.data.data)
                }).catch(error => {
                    reject(error);
                });
            });
        },
        autoSearch(ctx, data) {
            return new Promise((resolve, reject) => {
                axios.get('search/autocomplete?text=' + data).then(response => {
                    resolve(response);
                }).catch(error => {
                    reject(error);
                });
            });
        },
        aviaSearch(ctx, data) {
            return new Promise((resolve, reject) => {
                axios.get('avia/autocomplete?text=' + data).then(response => {
                    resolve(response);
                }).catch(error => {
                    reject(error);
                });
            });
        }
    },
    mutations: {
        updateIndexInfo(state, data) {
            state.slides = data.slides
            state.banners = core.orderingBanners(data.banners)
            state.recommended = data.recommendations
        }
    }
}
