<template>
  <div class="login-modal modal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <div class="close pointer" @click="close">
            <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g filter="url(#filter0_b_631_2916)">
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M13 26C20.1797 26 26 20.1797 26 13C26 5.8203 20.1797 0 13 0C5.8203 0 0 5.8203 0 13C0 20.1797 5.8203 26 13 26Z"
                      fill="#F5F5F5"/>
              </g>
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M17.8754 8.34029C17.466 7.93081 16.8065 7.9264 16.4024 8.33045L12.9336 11.7993L9.54098 8.40673C9.1315 7.99725 8.46754 7.99731 8.05799 8.40686C7.64844 8.81641 7.64838 9.48036 8.05787 9.88984L11.4504 13.2824L8.1146 16.6183C7.71056 17.0223 7.71496 17.6818 8.12445 18.0913C8.53393 18.5008 9.19342 18.5052 9.59747 18.1011L12.9333 14.7653L16.326 18.158C16.7355 18.5675 17.3994 18.5674 17.809 18.1579C18.2185 17.7483 18.2186 17.0844 17.8091 16.6749L14.4164 13.2822L17.8853 9.81331C18.2893 9.40927 18.2849 8.74978 17.8754 8.34029Z"
                    fill="black"/>
              <defs>
                <filter id="filter0_b_631_2916" x="-45.7896" y="-45.7896" width="117.579" height="117.579"
                        filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                  <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                  <feGaussianBlur in="BackgroundImage" stdDeviation="22.8948"/>
                  <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_631_2916"/>
                  <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_631_2916" result="shape"/>
                </filter>
              </defs>
            </svg>
          </div>
        </div>
        <div class="modal-body">

          <div class="image" v-if="this.state === 0">
            <img src="@/assets/images/modal/login/login.png">
          </div>
          <div class="image" v-else>
            <img src="@/assets/images/modal/login/login-2.png">
          </div>

          <template v-if="state === 0">
            <form class="login-form">
              <label>Введите номер телефона</label>
              <masked-input
                  class="masked"
                  mask="\+\7-111-111-11-11"
                  placeholder="Номер телефона"
                  v-model="data.phone"/>
              <span>На указанный номер вам прийдёт код в смс сообщении.</span>
            </form>
            <button type="button" class="btn btn-success" :class="phoneLength < 16 ? 'disabled' : ''" @click="login"
                    :disabled="phoneLength < 16">
              Отправить код
            </button>
          </template>

          <template v-if="state === 1">

            <div class="login-form">
              <label>Введите код из СМС</label>

              <div class="replaced-phone">
                <span>{{ data.phone }}</span>
                <a class="btn btn-outline"
                   @click="changePhone">Изменить
                </a>
              </div>

              <input
                  type="number"
                  class="number-code"
                  :class="getErrorCode ? 'error' : ''"
                  placeholder="••••"
                  :maxlength="4"
                  v-model="data2.code"
                  ref="code"/>

              <span
                  class="error-code"
                  v-if="getErrorCode">Неверный код. Попробуйте еще раз
              </span>
            </div>
            <button
                type="button"
                class="btn btn-success"
                :class="phoneLength < 16 ? 'disabled' : ''"
                @click="login"
                :disabled="phoneLength < 16">
              Отправить код ещё раз
            </button>

          </template>

          <div class="agreement">
            <p>Я даю <span>согласие</span> на получение рекламных сообщений.</p>
            <div class="subscribe-toggle">
              <input type="checkbox" name="checkbox1" id="checkbox1" class="ios-toggle" v-model="data.messages"/>
              <label for="checkbox1" class="checkbox-label"></label>
            </div>
          </div>

          <div class="agreement-2">
            <p>Нажимая "Отправить код", я соглашаюсь с <a href="/approval">Условиями продажи, Политикой
              конфиденциальности и Политикой в отношении обработки персональных данных.</a>
            </p>
          </div>

          <div class="social">
            <svg width="27" height="16" viewBox="0 0 27 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M22.8123 9.66176C23.6915 10.5202 24.6195 11.3278 25.4081 12.2728C25.7565 12.6928 26.0862 13.1261 26.3386 13.6134C26.6961 14.3062 26.3723 15.0686 25.7511 15.11L21.8896 15.1082C20.8936 15.1908 20.0991 14.7899 19.4311 14.1089C18.8965 13.5644 18.4013 12.9848 17.8873 12.4219C17.6765 12.1918 17.4559 11.9753 17.1924 11.8041C16.6653 11.462 16.2077 11.5667 15.9064 12.1165C15.5997 12.6757 15.53 13.2949 15.4999 13.9181C15.4585 14.8274 15.1837 15.0665 14.2703 15.1081C12.3183 15.2001 10.4658 14.9048 8.74483 13.9201C7.22756 13.0521 6.05104 11.8265 5.02693 10.4391C3.03313 7.73764 1.50618 4.76906 0.133867 1.71734C-0.175043 1.02979 0.0508799 0.66072 0.809513 0.647622C2.06923 0.623135 3.32878 0.624896 4.59 0.645862C5.102 0.653369 5.44094 0.947007 5.63864 1.43069C6.32019 3.10653 7.15415 4.70093 8.20093 6.1788C8.47971 6.57225 8.76398 6.96575 9.16872 7.24267C9.61653 7.5493 9.95748 7.44768 10.1681 6.94882C10.3018 6.63251 10.3604 6.29182 10.3905 5.95309C10.4903 4.78759 10.5034 3.62407 10.3284 2.46267C10.2209 1.73784 9.81268 1.2686 9.08941 1.13146C8.72034 1.06152 8.77527 0.924176 8.95398 0.713525C9.26434 0.350205 9.55626 0.124023 10.1382 0.124023H14.5023C15.1893 0.259608 15.3419 0.56826 15.436 1.25954L15.4397 6.1071C15.4322 6.3747 15.5734 7.1689 16.0554 7.346C16.4412 7.47211 16.6955 7.16346 16.927 6.91885C17.9718 5.80989 18.7176 4.49929 19.3838 3.1422C19.6795 2.54545 19.9337 1.92576 20.1801 1.30655C20.3626 0.847091 20.6491 0.621013 21.1666 0.631004L25.3668 0.634732C25.4913 0.634732 25.6174 0.636544 25.738 0.6572C26.4457 0.777823 26.6397 1.08228 26.4211 1.77341C26.0767 2.85772 25.4065 3.76136 24.7512 4.66915C24.0508 5.63833 23.3016 6.57437 22.607 7.5493C21.9688 8.43974 22.0195 8.88848 22.8123 9.66176Z"
                  fill="#383838"/>
            </svg>
            <svg width="17" height="15" viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M16.9714 1.10951L13.8753 13.0426C13.7296 13.6046 13.0557 13.8942 12.5032 13.6273L8.58736 11.7369L6.73574 14.5697C6.23193 15.3418 4.95705 15.0069 4.95705 14.1041V10.9477C4.95705 10.7036 5.06627 10.4708 5.25449 10.3005L12.8858 3.48809C12.8797 3.40293 12.7825 3.32919 12.6855 3.39153L3.57916 9.31834L0.519475 7.84232C-0.196818 7.49599 -0.166464 6.52518 0.574085 6.22437L15.6604 0.0763076C16.3828 -0.21895 17.1597 0.388596 16.9714 1.10951Z"
                  fill="#383838"/>
            </svg>
          </div>

          <div class="copyright">
            &#169; 2022 "OOO Аламат"
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import maskedInput from "vue-masked-input";
import {mapGetters} from "vuex";

export default {
  name: 'login-modal',
  components: {maskedInput},
  data: function () {
    return {
      data: {
        phone: '',
        device: 'web',
        messages: 0,
      },
      data2: {
        token: '',
        code: '',
      },
      state: 0,
    }
  },
  watch: {
    'data2.code': function (newVal) {
      if (newVal.length >= 4) {
        this.$store.dispatch('checkCode', this.data2)
      }
    },
  },
  async mounted() {
    this.data.device = this.$store.state.device;
    this.$nextTick(() => {
      if (this.$store.state.phone) {
        this.data.phone = this.$store.state.phone;
      }
    })
  },
  computed: {
    ...mapGetters(['getErrorCode']),
    phoneLength: function () {
      let phone = this.data.phone;
      phone = phone.replaceAll('_', '');
      return phone.length;
    }
  },
  methods: {
    login: function () {
      this.$store.dispatch('login', {
        phone: this.data.phone.replaceAll('-', ''),
        device: this.data.device,
        adv_messages: this.data.messages,
      }).then((res) => {
        if (res.data.result === 'ok') {
          this.data2.token = res.data.data.token;
          this.state = 1;
        }
      });
    },
    changePhone: function () {
      this.$store.state.errorCode = ''
      this.data.phone = '';
      this.state = 0;
    },
    close: function () {
      this.$root.$emit('openModal', 'login');
    },
  }
}
</script>

<style scoped>

@media screen and (max-width: 992px) {
  .login-modal .modal-dialog {
    bottom: 0;
    left: 0;
    outline: none;
    overflow: hidden;
    position: fixed;
    width: 100vw;
    z-index: 1050;
    margin: 0;
    max-width: 100%;
  }

  .login-modal .modal-content {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type='number'],
input[type="number"]:hover,
input[type="number"]:focus {
  appearance: none;
  -moz-appearance: textfield;
}
.login-modal .login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-modal .login-form label {
  font-family: 'dinpro_bold';
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
}

.login-modal .login-form input.masked {
  width: 239px;
  background: #F7F7F7;
  border-radius: 8px;
  border: none;
  padding: 10px 20px;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  margin: 12px 0 12px 0;
}

.login-modal .login-form span {
  text-align: center;
  max-width: 239px;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: #000000;
}

.login-modal .login-form input::placeholder {
  color: #BDBDBD;
}

.login-modal .replaced-phone {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: #F7F7F7;
  border-radius: 8px;
  width: 239px;
  height: 40px;
  padding: 15px 15px;
  margin-top: 12px;
}

.login-modal .replaced-phone span {
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}

.login-modal .replaced-phone .btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 76px;
  height: 24px;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #43B02A;
  margin-left: 15px;
}

.login-modal .login-form .number-code_wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0.5px solid #43B02A;
  width: 240px;
  height: 40px;
  border-radius: 8px;
}

.login-modal .login-form .number-code_wrap.error {
  border: 0.5px solid #FF5252;
  color: #FF5252;
}

.login-modal .login-form .number-code_wrap input {
  width: 24px;
  text-indent: 0;
  letter-spacing: 0.5em;
  text-align: center;
  border: none;
  font-size: 24px;
  font-family: 'dinpro_bold';
  font-weight: bold;
}


.login-modal .login-form input.number-code {
  font-family: 'dinpro_bold';
  font-weight: bold;
  width: 239px;
  height: 40px;
  border: 0.5px solid #43B02A;
  border-radius: 8px;
  text-align: center;
  font-size: 24px;
  margin: 12px 0 6px 0;
  text-indent: 0;
  letter-spacing: 0.5em;
}

.login-modal .login-form input.number-code.error {
  border: 0.5px solid #FF5252;
  color: #FF5252;
}

.login-modal .login-form span.error-code {
  font-weight: bold;
  font-size: 12px;
  line-height: 19px;
  color: #FF5252;
}

.login-modal .login-form input.number-code::-webkit-input-placeholder {
  color: #BFBFBF;
}

.login-modal .agreement {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 50px;
}

.login-modal .agreement p {
  font-weight: 300;
  font-size: 11px;
  line-height: 11px;
  color: #9C9C9C;
}

.login-modal .agreement p span {
  color: #43B02A;
}

.login-modal .agreement-2 {
  margin-top: 5px;
  width: 335px;
}

.login-modal .agreement-2 p {
  font-weight: 300;
  font-size: 11px;
  line-height: 11px;
  color: #9C9C9C;
}

.login-modal .agreement-2 p a {
  color: #43B02A;
  text-decoration: underline;
}

.login-modal .agreement .subscribe-toggle {
  width: 41px;
}

.login-modal .agreement .subscribe-toggle .checkbox-label {
  width: 41px;
  height: 23px;
}

.login-modal .agreement .subscribe-toggle .checkbox-label:before {
  width: 22px;
  height: 22px;
}

.login-modal .agreement .subscribe-toggle .ios-toggle:checked + .checkbox-label:before {
  width: 22px;
  height: 22px;
  left: calc(100% - 22px);
}

.login-modal .btn.btn-success {
  max-width: 239px;
  padding: 10px 20px;
  margin-top: 36px;
}

.login-modal .social {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 12px;
}

.login-modal .social svg:not(:last-child) {
  margin-right: 25px;
}

.login-modal .copyright {
  margin-top: 15px;
  margin-bottom: 15px;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #BDBDBD;
}

</style>
