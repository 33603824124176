<template>
  <div class="mobile-wrapper">
    <div class="mobile-header">
      <div class="mobile-header-close" @click="close">
        <svg width="11" height="18" viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd"
                d="M2.43513 8.91619L10.1348 1.21656L8.9182 2.17716e-07L-0.000948719 8.91913L1.21562 10.1357L1.21856 10.1327L8.91714 17.8314L10.1337 16.6148L2.43513 8.91619Z"
                fill="black"/>
        </svg>
      </div>
      <div class="mobile-header-title">Бонусная система</div>
    </div>
    <div class="mobile-body">
      <div class="profile-body-info">
        <div class="left">
          <div class="score">{{ data.scores }} {{ this.$core.declination(data.scores, 'балл', 'балла', 'баллов') }}</div>
          <span>Рейтинг: <b>{{ data.rating.name }}</b></span>
          <span>*1 балл = 1 рубль</span>
          <div class="form-group">
            <label>Промокод</label>
            <input type="text" placeholder="Промокод" v-model="data.promo" id="textToCopy"/>
            <i class="fa-solid fa-copy" @click="copyText"></i>
          </div>
          <span>
              <b>Дарим баллы за дружбу<br>
              Каждый друг, который воспользуется вашим промокодом и<br>
              оформит заказ, получит скидку и принесёт 100 бонусных<br>
                баллов. Больше друзей — больше баллов</b>
            </span>
        </div>
        <div class="right">
          <div class="line-lvl">
            <div class="lvl-item active">
              <img src="@/assets/images/profile/profile-lvl-1.png">
              <span class="lvl-name">Любитель путешествий</span>
              <span class="lvl-info">1%<br>с каждой покупки,<br>куплено<br>на 25 000 руб</span>
            </div>
            <div class="between">
              <svg width="74" height="15" viewBox="0 0 74 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <ellipse cx="66.449" cy="7.5" rx="7.55102" ry="7.5" fill="#96A3AE"/>
                <ellipse cx="43.7961" cy="7.5" rx="7.55102" ry="7.5" fill="#96A3AE"/>
                <ellipse cx="22.6531" cy="7.5" rx="6.04082" ry="6" fill="#96A3AE"/>
                <ellipse cx="4.53061" cy="7.5" rx="4.53061" ry="4.5" fill="#96A3AE"/>
              </svg>
            </div>
            <div class="lvl-item">
              <img src="@/assets/images/profile/profile-lvl-2.png">
              <span class="lvl-name">Фанат путешествий</span>
              <span class="lvl-info">2,5%<br>с каждой покупки,<br>необходимо купить ещё<br>на 95 000 руб</span>
            </div>
            <div class="between">
              <svg width="74" height="15" viewBox="0 0 74 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <ellipse cx="66.449" cy="7.5" rx="7.55102" ry="7.5" fill="#96A3AE"/>
                <ellipse cx="43.7961" cy="7.5" rx="7.55102" ry="7.5" fill="#96A3AE"/>
                <ellipse cx="22.6531" cy="7.5" rx="6.04082" ry="6" fill="#96A3AE"/>
                <ellipse cx="4.53061" cy="7.5" rx="4.53061" ry="4.5" fill="#96A3AE"/>
              </svg>
            </div>
            <div class="lvl-item">
              <img src="@/assets/images/profile/profile-lvl-3.png">
              <span class="lvl-name">Гуру путешествий </span>
              <span class="lvl-info">4,5%<br>с каждой покупки, необходимо купить ещё<br>на 195 000 руб</span>
              <span class="lvl-vip">
                  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="15" height="15" rx="7.5" fill="#43B02A"/>
                    <path
                        d="M6.45473 11.591C6.18793 11.591 5.93558 11.472 5.77337 11.2679L3.58249 8.50982C3.29754 8.1511 3.37172 7.64032 3.74802 7.36857C4.12494 7.09669 4.66039 7.16788 4.94547 7.52636L6.38644 9.34016L10.0106 3.79309C10.2601 3.41146 10.7869 3.29469 11.1881 3.5322C11.5888 3.76994 11.7112 4.27254 11.4616 4.65452L7.18037 11.207C7.03163 11.4348 6.77488 11.5781 6.49373 11.5903C6.48061 11.5908 6.46773 11.591 6.45473 11.591Z"
                        fill="white"/>
                  </svg>
                  <span>Vip - статус</span>
                  <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M5 10C6.32608 10 7.59785 9.47322 8.53553 8.53553C9.47322 7.59785 10 6.32608 10 5C10 3.67392 9.47322 2.40215 8.53553 1.46447C7.59785 0.526784 6.32608 0 5 0C3.67392 0 2.40215 0.526784 1.46447 1.46447C0.526784 2.40215 0 3.67392 0 5C0 6.32608 0.526784 7.59785 1.46447 8.53553C2.40215 9.47322 3.67392 10 5 10ZM5.58125 4.1175L4.95625 7.05813C4.9125 7.27063 4.97438 7.39125 5.14625 7.39125C5.2675 7.39125 5.45062 7.3475 5.575 7.2375L5.52 7.4975C5.34062 7.71375 4.945 7.87125 4.60437 7.87125C4.165 7.87125 3.97813 7.6075 4.09937 7.04688L4.56063 4.87937C4.60063 4.69625 4.56437 4.63 4.38125 4.58562L4.09937 4.535L4.15062 4.29688L5.58187 4.1175H5.58125ZM5 3.4375C4.83424 3.4375 4.67527 3.37165 4.55806 3.25444C4.44085 3.13723 4.375 2.97826 4.375 2.8125C4.375 2.64674 4.44085 2.48777 4.55806 2.37056C4.67527 2.25335 4.83424 2.1875 5 2.1875C5.16576 2.1875 5.32473 2.25335 5.44194 2.37056C5.55915 2.48777 5.625 2.64674 5.625 2.8125C5.625 2.97826 5.55915 3.13723 5.44194 3.25444C5.32473 3.37165 5.16576 3.4375 5 3.4375Z"
                        fill="#5A6C7D"/>
                  </svg>
                </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapGetters} from "vuex";

export default {
  name: 'my-bonuses',
  data: function () {
    return {
      data: {
        promo: '',
        scores: 0,
        rating: [],
      }
    }
  },
  computed: mapGetters(['userInfo']),
  async mounted() {
    this.getUserInfo();
  },
  methods: {
    getUserInfo: function () {
      this.$store.dispatch('getUserInfo').then(() => {
        this.data.promo = this.userInfo.promo;
        this.data.scores = this.userInfo.scores;
        this.data.rating = this.userInfo.rating;
      });
    },
    copyText: function () {
      let input = document.getElementById("textToCopy");
      input.select()
      document.execCommand("copy");
    },
    close: function () {
      this.$root.$emit('triggerProfileTab', 'myBonuses');
    }
  }
}
</script>
