<template>
  <div class="success-booking modal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
        </div>
        <div class="modal-body">
          <svg width="97" height="75" viewBox="0 0 97 75" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5 37.5L37.625 70L92 5" stroke="#FED42B" stroke-width="10" stroke-linecap="round"
                  stroke-linejoin="round"/>
          </svg>

          <h1>Заказ оформлен</h1>
          <p>Всю необходимую информацию направили на вашу электронную почту, а так же вы можете посмотреть в личном
            кабинете.</p>

        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'success-booking-modal',
  data: function () {
    return {}
  },
  async mounted() {

  },
  methods: {}
}
</script>

<style scoped>
.success-booking .modal-dialog .modal-body h1 {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  margin-top: 30px;
}

.success-booking .modal-dialog .modal-body p {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  max-width: 335px;
  margin: 30px 0 30px 0;
  text-align: center;
}
</style>
