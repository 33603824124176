<template>
    <div class="review-modal modal">
      <div class="modal-dialog modal-avg">
        <div class="modal-content">
          <div class="modal-header">
            <div class="close pointer" @click="close">
              <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#filter0_b_631_2916)">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M13 26C20.1797 26 26 20.1797 26 13C26 5.8203 20.1797 0 13 0C5.8203 0 0 5.8203 0 13C0 20.1797 5.8203 26 13 26Z"
                        fill="#F5F5F5"/>
                </g>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M17.8754 8.34029C17.466 7.93081 16.8065 7.9264 16.4024 8.33045L12.9336 11.7993L9.54098 8.40673C9.1315 7.99725 8.46754 7.99731 8.05799 8.40686C7.64844 8.81641 7.64838 9.48036 8.05787 9.88984L11.4504 13.2824L8.1146 16.6183C7.71056 17.0223 7.71496 17.6818 8.12445 18.0913C8.53393 18.5008 9.19342 18.5052 9.59747 18.1011L12.9333 14.7653L16.326 18.158C16.7355 18.5675 17.3994 18.5674 17.809 18.1579C18.2185 17.7483 18.2186 17.0844 17.8091 16.6749L14.4164 13.2822L17.8853 9.81331C18.2893 9.40927 18.2849 8.74978 17.8754 8.34029Z"
                      fill="black"/>
                <defs>
                  <filter id="filter0_b_631_2916" x="-45.7896" y="-45.7896" width="117.579" height="117.579"
                          filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feGaussianBlur in="BackgroundImage" stdDeviation="22.8948"/>
                    <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_631_2916"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_631_2916" result="shape"/>
                  </filter>
                </defs>
              </svg>
            </div>
          </div>
          <div class="modal-body-df">
            <h1 class="title-modal">Написать отзыв</h1>
            <span class="title-help-modal">Оценка и отзыв публикуются для всех</span>
            <div class="row">
              <div class="col-md-6">
                <div class="review-hotel-card">
                  <div class="images" v-if="item && item.images.length > 0">
                    <img class="image" :src="item.images[0].url">
                  </div>
                  <div class="review-hotel-name">
                    <span>{{ item.info.name }}</span>
                    <div class="review-hotel-stars">
                      <img src="@/assets/images/catalog/star.png" v-for="(star, index) in parseInt(item.info.stars)" :key="index">
                    </div>
                  </div>
                  <div class="review-hotel-position">
                    {{ item.info.address }}
                  </div>
                </div>
              </div>
              <div class="col-md-6 mt-3">
                <div class="select-service-rate">
                  <div class="rate-item">
                    <label>Размещение:</label>
                    <star-rating :border-width="4" border-color="#d8d8d8" :rounded-corners="true" :show-rating="false" :star-size="21" v-model="data.placement"></star-rating>
                  </div>
                  <div class="rate-item">
                    <label>Сервис:</label>
                    <star-rating :border-width="4" border-color="#d8d8d8" :rounded-corners="true" :show-rating="false" :star-size="21" v-model="data.service"></star-rating>
                  </div>
                  <div class="rate-item">
                    <label>Питание:</label>
                    <star-rating :border-width="4" border-color="#d8d8d8" :rounded-corners="true" :show-rating="false" :star-size="21" v-model="data.food"></star-rating>
                  </div>
                </div>
                <div class="review-commentary">
                  <label>Комментарий:</label>
                  <textarea rows="5" cols="50" placeholder="Поделитесь мнением про плюсы и минусы этого места"></textarea>
                </div>
                <div class="attachment">
                  <label>Дополните отзыв фотографиями:</label>
                  <div class="attachment-images">
                    <div class="attachment-item" @click="$refs.file.click()">
                      <img src="@/assets/images/modal/review/review-image.svg">
                    </div>
                    <div class="attachment-item" v-for="(image, index) in showImages" :key="index">
                      <div class="remove-image" @click="showImages.splice(index, 1); data.images.splice(index, 1)">
                        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g filter="url(#filter0_b_2284_14435)">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M7.5 15C11.6421 15 15 11.6421 15 7.5C15 3.35786 11.6421 0 7.5 0C3.35786 0 0 3.35786 0 7.5C0 11.6421 3.35786 15 7.5 15Z"
                                  fill="#F5F5F5"/>
                          </g>
                          <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M10.3125 4.81154C10.0763 4.5753 9.6958 4.57276 9.4627 4.80586L7.46118 6.80737L5.50417 4.85036C5.26793 4.61412 4.88487 4.61415 4.6486 4.85043C4.41232 5.08671 4.41228 5.46976 4.64852 5.706L6.60554 7.66301L4.68126 9.5873C4.44815 9.8204 4.4507 10.2009 4.68694 10.4371C4.92318 10.6734 5.30365 10.6759 5.53676 10.4428L7.46104 8.51851L9.4186 10.4761C9.65484 10.7123 10.0379 10.7123 10.2742 10.476C10.5105 10.2397 10.5105 9.85668 10.2742 9.62044L8.31668 7.66287L10.3182 5.66136C10.5513 5.42826 10.5488 5.04778 10.3125 4.81154Z"
                                fill="black"/>
                          <defs>
                            <filter id="filter0_b_2284_14435" x="-45.7896" y="-45.7896" width="106.579" height="106.579"
                                    filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                              <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                              <feGaussianBlur in="BackgroundImageFix" stdDeviation="22.8948"/>
                              <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_2284_14435"/>
                              <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_2284_14435" result="shape"/>
                            </filter>
                          </defs>
                        </svg>
                      </div>
                      <img :src="image">
                    </div>
                  </div>
                  <input class="d-none" type="file" ref="file" accept=".jpeg, .jpg, .png" @change="uploadImage"/>
                </div>
                <div class="buttons">
                  <button class="btn btn-info" @click="send">Отправить</button>
                  <button class="btn btn-canceled" @click="close">Отменить</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import StarRating from 'vue-star-rating';
export default {
  name: 'review-modal',
  components: { StarRating},
  data: function () {
    return {
      data: {
        placement: 5,
        service: 5,
        food: 5,
        images: []
      },
      showImages: [],
      item: [],
    }
  },
  mounted() {
    this.item = JSON.parse(localStorage.getItem('dataRoom'))
  },
  computed: {},
  methods: {
    uploadImage: function (e) {
      const file = e.target.files[0]
      this.image = file
      this.data.images.push(file)
      this.showImages.push(URL.createObjectURL(file))
    },
    send: function () {
      console.log(true)
    },
    close: function () {
      this.$root.$emit('openModal', 'review');
    },
  }
}
</script>

<style scoped>
.review-modal .modal-body-df .title-modal {;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  color: #000000;
}

.review-modal .modal-body-df .title-help-modal {
  display: block;
  font-family: 'dinpro_light';
  font-weight: 300;
  font-size: 16px;
  line-height: 11px;
  color: #000000;
  margin-top: 10px;
}

.review-modal .modal-body-df .review-hotel-card {
  margin-top: 25px;
}

.review-modal .modal-body-df .review-hotel-card .images .image {
  border-radius: 10px;
}

.review-modal .modal-body-df .review-hotel-card .review-hotel-name {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 15px;
}

.review-modal .modal-body-df .review-hotel-card .review-hotel-name span {
  font-weight: bold;
  font-size: 20px;
  line-height: 16px;
  color: #000000;
}

.review-modal .modal-body-df .review-hotel-card .review-hotel-name .review-hotel-stars {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.review-modal .modal-body-df .review-hotel-card .review-hotel-name .review-hotel-stars img {
  width: 12px;
  height: 12px;
}

.review-modal .modal-body-df .review-hotel-card .review-hotel-name .review-hotel-stars img {
  margin-left: 5px;
}

.review-modal .modal-body-df .review-hotel-card .review-hotel-position {
  font-weight: 300;
  font-size: 16px;
  line-height: 16px;
  color: #000000;
  margin-top: 5px;
  max-width: 325px;
}

.review-modal .modal-body-df .select-service-rate {
  display: flex;
  flex-direction: column;
}

.review-modal .modal-body-df .select-service-rate .rate-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
}

.review-modal .modal-body-df .select-service-rate .rate-item label {
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: #000000;
  width: 100px;
  margin-right: 15px;
}

.review-modal .modal-body-df .review-commentary {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.review-modal .modal-body-df .review-commentary label {
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: #000000;
  margin-bottom: 15px;
}

.review-modal .modal-body-df .review-commentary textarea {
  padding: 10px 10px;
  width: 100%;
  max-width: 335px;
}

.review-modal .modal-body-df .attachment {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 5px;
}

.review-modal .modal-body-df .attachment label {
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: #000000;
  margin-bottom: 15px;
}

.review-modal .modal-body-df .attachment .attachment-images {
  display: flex;
  justify-content: flex-start;
}

.review-modal .modal-body-df .attachment .attachment-images .attachment-item {
  max-width: 60px;
  cursor: pointer;
  position: relative;
}

.review-modal .modal-body-df .attachment .attachment-images .attachment-item .remove-image {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
}

.review-modal .modal-body-df .attachment .attachment-images .attachment-item:not(:last-child) {
  margin-right: 10px;
}

.review-modal .modal-body-df .attachment .attachment-images .attachment-item img {
  width: 60px;
  height: 60px;
  border-radius: 5px;
}

.review-modal .modal-body-df .buttons {
  display: flex;
  justify-content: flex-start;
  margin-top: 30px;
}

.review-modal .modal-body-df .buttons .btn {
  width: 160px;
  height: 50px;
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
  color: #000000;
}

.review-modal .modal-body-df .buttons .btn:first-child {
  margin-right: 15px;
}

</style>
