<template>
  <div class="mobile-wrapper">
    <div class="mobile-header">
      <div class="mobile-header-close" @click="close">
        <svg width="11" height="18" viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd"
                d="M2.43513 8.91619L10.1348 1.21656L8.9182 2.17716e-07L-0.000948719 8.91913L1.21562 10.1357L1.21856 10.1327L8.91714 17.8314L10.1337 16.6148L2.43513 8.91619Z"
                fill="black"/>
        </svg>
      </div>
      <div class="mobile-header-title">Карты оплаты</div>
    </div>
    <div class="mobile-body">
      <div class="profile-cards">
        <div class="profile-cards-add" @click="addCard">
          <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="20" cy="20" r="20" fill="#383838"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M19.895 13C19.3159 13 18.8464 13.4695 18.8464 14.0487L18.8464 18.9403H14.0485C13.4694 18.9403 13 19.4099 13 19.9891C13 20.5682 13.4695 21.0378 14.0485 21.0378H18.8464L18.8464 25.9292C18.8464 26.5084 19.3159 26.9779 19.895 26.9779C20.4741 26.9779 20.9435 26.5084 20.9435 25.9292L20.9435 21.0378H25.7415C26.3206 21.0378 26.79 20.5682 26.79 19.9891C26.79 19.4099 26.3206 18.9403 25.7415 18.9403H20.9435L20.9435 14.0487C20.9435 13.4695 20.4741 13 19.895 13Z" fill="white"/>
          </svg>
          <span>Новый способ оплаты</span>
        </div>
        <div class="profile-cards-items" v-if="cards.length > 0">
          <div class="profile-card-item" v-for="card in cards" :key="card.id">
            <div class="svg">
              <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="22" height="22" rx="11" fill="#43B02A"/>
                <path d="M9.46681 17C9.07549 17 8.70539 16.8255 8.46747 16.5262L5.25419 12.4809C4.83626 11.9548 4.94506 11.2057 5.49697 10.8071C6.04978 10.4084 6.83512 10.5128 7.25323 11.0385L9.36665 13.6988L14.682 5.56306C15.048 5.00334 15.8207 4.83209 16.4091 5.18043C16.9968 5.52912 17.1763 6.26627 16.8102 6.8265L10.5311 16.4369C10.3129 16.771 9.93636 16.9811 9.524 16.999C9.50476 16.9997 9.48588 17 9.46681 17Z" fill="white"/>
              </svg>
            </div>
            <div class="image-type">
              <svg width="28" height="18" viewBox="0 0 28 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 1.89474C0 0.848305 0.895431 0 2 0H26C27.1046 0 28 0.848303 28 1.89474V16.1053C28 17.1517 27.1046 18 26 18H2C0.89543 18 0 17.1517 0 16.1053V1.89474Z" fill="white"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M26 0.473684H2C1.17157 0.473684 0.5 1.10991 0.5 1.89474V16.1053C0.5 16.8901 1.17157 17.5263 2 17.5263H26C26.8284 17.5263 27.5 16.8901 27.5 16.1053V1.89474C27.5 1.10991 26.8284 0.473684 26 0.473684ZM2 0C0.895431 0 0 0.848305 0 1.89474V16.1053C0 17.1517 0.89543 18 2 18H26C27.1046 18 28 17.1517 28 16.1053V1.89474C28 0.848303 27.1046 0 26 0H2Z" fill="#F3F3F3"/>
                <path d="M6.74515 9.65783C6.7799 9.53036 6.88033 9.49779 6.88033 9.4025C7.1168 8.60274 7.35243 7.83556 7.62153 7.10014C7.78934 6.55651 8.19448 6.30078 8.73395 6.30078C9.30648 6.30078 9.77815 6.30078 10.3511 6.30078C10.5871 6.30078 10.6206 6.42865 10.6206 6.62044C10.6206 8.34661 10.6206 10.1049 10.6206 11.8311C10.6206 12.0551 10.5871 12.0873 10.3511 12.0873C9.91248 12.0873 9.40734 12.0873 8.96873 12.0873C8.73353 12.0873 8.69963 11.9594 8.69963 11.8311C8.69963 11 8.69963 10.1049 8.69963 9.27383C8.69963 9.1142 8.69963 8.95417 8.69963 8.8263C8.66615 8.8263 8.66615 8.8263 8.56486 8.8263C8.53096 8.95417 8.42925 9.05027 8.3962 9.14637C8.05929 10.1054 7.78934 11.0318 7.52067 11.9912C7.48719 12.0233 7.35285 12.119 7.18376 12.119C6.91381 12.119 6.61081 12.119 6.30738 12.119C6.07134 12.119 6.03828 12.0873 5.97048 11.8629C5.70095 10.9039 5.36405 9.97708 5.02714 9.0181C5.02714 8.89024 4.99366 8.79414 4.99366 8.69844H4.95976C4.95976 8.8259 4.92586 8.922 4.92586 9.0181C4.92586 9.91315 4.92586 10.8721 4.92586 11.7676C4.92586 12.0873 4.79194 12.1508 4.522 12.1508C4.08381 12.1508 3.64646 12.1508 3.17437 12.1508C2.93833 12.1508 2.90485 12.119 2.90485 11.8946C2.90485 10.1693 2.90485 8.37838 2.90485 6.62044C2.87137 6.36471 2.93833 6.33295 3.10699 6.33295C3.71342 6.33295 4.28595 6.33295 4.89238 6.33295C5.36405 6.33295 5.73443 6.58868 5.83656 7.06797C6.10566 7.86772 6.30738 8.63451 6.57733 9.43346C6.61123 9.56132 6.71167 9.65742 6.71167 9.75312C6.67819 9.65783 6.74515 9.65783 6.74515 9.65783Z" fill="#40A548"/>
                <path d="M13.7398 9.82354C13.7751 9.69468 13.8788 9.59712 13.9137 9.50037C14.3293 8.69265 14.7105 7.91663 15.1253 7.04428C15.368 6.59184 15.7492 6.3333 16.2685 6.3333C16.719 6.3333 17.1351 6.3333 17.5847 6.3333C17.8278 6.3333 17.8618 6.36542 17.8618 6.59143C17.8618 8.33655 17.8618 10.1467 17.8618 11.9244C17.8618 12.0853 17.8278 12.1508 17.6191 12.1508C17.1691 12.1508 16.6497 12.1508 16.1992 12.1508C15.9561 12.1508 15.8529 12.1187 15.9217 11.8922C15.9217 10.9227 15.9217 10.0174 15.9217 9.08046C15.9217 8.95119 15.9217 8.85444 15.7836 8.69265C15.7488 8.82192 15.6451 8.85403 15.6102 8.95078C15.195 9.75932 14.8138 10.5992 14.3981 11.4069C14.155 11.9569 13.7747 12.1504 13.1517 12.1504C12.7012 12.1504 12.2864 12.1504 11.8355 12.1504C11.6621 12.1504 11.5584 12.1183 11.5924 11.924C11.5924 10.1142 11.5924 8.36867 11.5924 6.5268C11.5924 6.36542 11.6272 6.30078 11.8355 6.30078C12.3199 6.30078 12.8746 6.30078 13.359 6.30078C13.6017 6.30078 13.6017 6.42964 13.6017 6.55891C13.6017 7.46379 13.6017 8.36867 13.6017 9.33818C13.6017 9.46745 13.6017 9.59672 13.6017 9.72598C13.6017 9.75932 13.6719 9.82354 13.7398 9.82354Z" fill="#40A548"/>
                <path d="M18.3457 6.30078C18.4884 6.30078 18.5969 6.30078 18.6325 6.30078C20.0642 6.30078 21.46 6.30078 22.8917 6.30078C24.2518 6.30078 25.2905 7.38069 25.0762 8.43079C25.0762 8.4606 25.0762 8.4606 25.0402 8.55078C24.861 8.55078 24.6823 8.55078 24.5391 8.55078C23.4653 8.55078 22.4631 8.55078 21.4248 8.55078C20.1363 8.55078 18.8828 7.77047 18.4533 6.72037C18.3813 6.57057 18.3457 6.45058 18.3457 6.30078Z" fill="#1291D0"/>
                <path d="M25.104 9.09467C24.9267 9.7879 24.1072 10.2914 23.2889 10.3544C22.5773 10.3544 21.9367 10.3544 21.2241 10.3544C20.9757 10.3544 20.8692 10.386 20.8692 10.6063C20.8692 11.0159 20.8692 11.4251 20.8692 11.8981C20.8692 12.0553 20.8334 12.15 20.6199 12.15C20.1212 12.15 19.5523 12.15 19.0895 12.15C18.9118 12.15 18.8406 12.1183 18.8406 11.9294C18.8406 11.0472 18.8406 10.1658 18.8406 9.22064C18.8048 9.09467 18.8406 9 19.0179 9C20.9395 9 22.9327 9 24.89 9C24.9975 9.03129 25.0328 9.09467 25.104 9.09467Z" fill="#40A548"/>
              </svg>
            </div>
            <div class="number-card">
              {{ card.number }}
            </div>
            <div class="card-remove">
              <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="11" cy="11" r="11" fill="#F4F4F4"/>
                <path d="M12.0842 9.34766C11.925 9.34766 11.7959 9.47346 11.7959 9.62869V14.9402C11.7959 15.0953 11.925 15.2212 12.0842 15.2212C12.2435 15.2212 12.3726 15.0953 12.3726 14.9402V9.62869C12.3726 9.47346 12.2435 9.34766 12.0842 9.34766Z" fill="black"/>
                <path d="M9.91555 9.34766C9.75628 9.34766 9.6272 9.47346 9.6272 9.62869V14.9402C9.6272 15.0953 9.75628 15.2212 9.91555 15.2212C10.0748 15.2212 10.2039 15.0953 10.2039 14.9402V9.62869C10.2039 9.47346 10.0748 9.34766 9.91555 9.34766Z" fill="black"/>
                <path d="M6.81896 8.57252V15.4965C6.81896 15.9057 6.97293 16.2901 7.2419 16.5658C7.50964 16.8424 7.88224 16.9993 8.27218 17H13.7278C14.1179 16.9993 14.4905 16.8424 14.7581 16.5658C15.0271 16.2901 15.181 15.9057 15.181 15.4965V8.57252C15.7157 8.4342 16.0622 7.93076 15.9907 7.39603C15.919 6.86142 15.4517 6.4615 14.8984 6.46139H13.4221V6.1101C13.4238 5.81469 13.304 5.53103 13.0894 5.32234C12.8748 5.11377 12.5833 4.99762 12.2802 5.00004H9.71978C9.41668 4.99762 9.12518 5.11377 8.9106 5.32234C8.69603 5.53103 8.57619 5.81469 8.57788 6.1101V6.46139H7.10156C6.54829 6.4615 6.08096 6.86142 6.00933 7.39603C5.9378 7.93076 6.28427 8.4342 6.81896 8.57252ZM13.7278 16.4379H8.27218C7.77918 16.4379 7.39565 16.0252 7.39565 15.4965V8.59722H14.6044V15.4965C14.6044 16.0252 14.2208 16.4379 13.7278 16.4379ZM9.15457 6.1101C9.15266 5.96377 9.21168 5.82293 9.31823 5.71963C9.42467 5.61633 9.56952 5.55957 9.71978 5.5621H12.2802C12.4305 5.55957 12.5753 5.61633 12.6818 5.71963C12.7883 5.82282 12.8473 5.96377 12.8454 6.1101V6.46139H9.15457V6.1101ZM7.10156 7.02345H14.8984C15.1851 7.02345 15.4175 7.24992 15.4175 7.5293C15.4175 7.80869 15.1851 8.03516 14.8984 8.03516H7.10156C6.8149 8.03516 6.58253 7.80869 6.58253 7.5293C6.58253 7.24992 6.8149 7.02345 7.10156 7.02345Z" fill="black"/>
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapGetters} from "vuex";

export default {
  name: 'my-cards',
  data: function () {
    return {
      cards: []
    }
  },
  async mounted() {
    this.getUserCards()
  },
  computed: {
    ...mapGetters(['userInfo'])
  },
  methods: {
    addCard: function () {
      const widget = new window.cp.CloudPayments();
      widget.pay('charge', {
            publicId: 'pk_41a654718a436d46d7557379b871c', //id из личного кабинета
            description: 'Добавление новой карты', //назначение
            amount: 11, //сумма
            currency: 'RUB', //валюта
            accountId: 1, //идентификатор плательщика (необязательно)
            skin: "modern", //дизайн виджета (необязательно)
          },
          {
            onSuccess: function (options) { // success
              console.log(options)
            },
            onFail: function (reason, options) { // fail
              console.log(reason)
              console.log(options)
            },
            onComplete: function (paymentResult, options) { //Вызывается как только виджет получает от api.cloudpayments ответ с результатом транзакции.
              console.log(paymentResult)
              console.log(options)
            }
          }
      )
    },
    getUserCards: function () {
      this.$store.dispatch('getUserInfo').then(() => {
        this.cards = this.userInfo.cards
      });
    },
    close: function () {
      this.$root.$emit('triggerProfileTab', 'myCards');
    },
  }
}
</script>
