import axios from 'axios'

export default {
    state: {},
    getters: {},
    actions: {
        getFilters() {
            return new Promise((resolve, reject) => {
                axios.get('/hotel/search/filters').then(response => {
                    resolve(response);
                }).catch(error => {
                    reject(error);
                });
            });
        },
        catalogSearch(ctx, data) {
            return new Promise((resolve, reject) => {
                axios.get('search?' + data).then(response => {
                    resolve(response);
                }).catch(error => {
                    reject(error);
                });
            });
        },
        follow(ctx, data) {
            return new Promise((resolve, reject) => {
                axios.post('/hotel/favorite', data).then(response => {
                    resolve(response);
                }).catch(error => {
                    reject(error);
                });
            });
        },
        unfollow(ctx, data) {
            return new Promise((resolve, reject) => {
                axios.post('/hotel/unfavorite', data).then(response => {
                    resolve(response);
                }).catch(error => {
                    reject(error);
                });
            });
        },
    },
    mutations: {}
}
