<template>
  <div class="mobile-wrapper">
    <div class="mobile-header">
      <div class="mobile-header-close" @click="close">
        <svg width="11" height="18" viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd"
                d="M2.43513 8.91619L10.1348 1.21656L8.9182 2.17716e-07L-0.000948719 8.91913L1.21562 10.1357L1.21856 10.1327L8.91714 17.8314L10.1337 16.6148L2.43513 8.91619Z"
                fill="black"/>
        </svg>
      </div>
      <div class="mobile-header-title">Мои данные</div>
    </div>
    <div class="mobile-body">
      <div class="profile-body-data">
        <div class="left">
          <div class="image">
            <img src="@/assets/images/profile/profile-logo-2.png" v-if="photo === null">
            <img :src="photo" v-else>
          </div>
          <a @click="$refs.file.click()">Редактировать фото</a>
          <input class="d-none" type="file" ref="file" v-on:change="handleFileUpload()"/>
        </div>
        <div class="right">
          <div class="block">
            <div class="form-group">
              <label>Имя</label>
              <input type="text" placeholder="Имя" v-model="data.name" @blur="update"/>
            </div>
            <div class="form-group">
              <label>Фамилия</label>
              <input type="text" placeholder="Фамилия" v-model="data.last_name" @blur="update"/>
            </div>
          </div>
          <div class="block">
            <div class="form-group phone">
              <label>Телефон</label>
              <input type="text" placeholder="+7-999-999-99-99" v-model="data.phone" @blur="update"/>
              <span>
                  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
      d="M4.46681 12C4.07549 12 3.70539 11.8255 3.46747 11.5262L0.254191 7.48094C-0.163744 6.95482 -0.0549441 6.20567 0.496968 5.8071C1.04978 5.40835 1.83512 5.51275 2.25323 6.03853L4.36665 8.69878L9.68201 0.563063C10.048 0.0033448 10.8207 -0.167914 11.4091 0.180432C11.9968 0.52912 12.1763 1.26627 11.8102 1.8265L5.53107 11.4369C5.31293 11.771 4.93636 11.9811 4.524 11.999C4.50476 11.9997 4.48588 12 4.46681 12Z"
      fill="#43B02A"/>
  </svg>
                  Подтверждён
                </span>
            </div>
            <div class="form-group">
              <label>Email</label>
              <input type="text" placeholder="Email" v-model="data.email" @blur="update"/>
            </div>
          </div>
          <div class="block">
            <div class="form-group birthday">
              <label>Дата рождения</label>
              <div class="input-flex">
                <input
                    type="number"
                    placeholder="12"
                    v-model="birth.day"
                    :min="1"
                    :max="31"
                    :maxlength="2"/>
                <select v-model="birth.month">
                  <option value="01">Январь</option>
                  <option value="02">Февраль</option>
                  <option value="03">Март</option>
                  <option value="04">Апрель</option>
                  <option value="05">Май</option>
                  <option value="06">Июнь</option>
                  <option value="07">Июль</option>
                  <option value="08">Август</option>
                  <option value="09">Сентябрь</option>
                  <option value="10">Октябрь</option>
                  <option value="11">Ноябрь</option>
                  <option value="12">Декабрь</option>
                </select>
                <input
                    type="number"
                    placeholder="2022"
                    v-model="birth.year"
                    :maxlength="4"/>
              </div>
            </div>
            <div class="form-group">
              <label>Пол</label>
              <div class="sex-group">
                <div
                    class="sex-item"
                    :class="[data.gender === 'male' ? 'active': '']"
                    @click="switchGender('male')">Мужской
                </div>
                <div
                    class="sex-item"
                    :class="[data.gender === 'female' ? 'active': '']"
                    @click="switchGender('female')">Женский
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: 'my-data',
  data: function () {
    return {
      loading: true,
      data: {
        name: '',
        phone: null,
        last_name: '',
        middle_name: '',
        email: null,
        gender: '',
        birth_date: '',
        adv_messages: false,
      },
      photo: null,
      referral_code: '',
      birth: {
        year: '',
        month: '01',
        day: '',
      },
      orders: [],
      scores: 0,
      rating: [],
      notebooks: [],
      cards: [],
      totalCountOrder: 0,
      filters: {
        limit: 3,
        offset: 0
      },
      file: null,
    }
  },
  watch: {
    birth: {
      handler(newValue) {
        if (!this.loading) {
          this.data.birth_date = ''
          this.data.birth_date += newValue.year + '-'
          this.data.birth_date += newValue.month + '-'
          this.data.birth_date += newValue.day

          if (this.birth.year &&
              this.birth.month &&
              this.birth.day &&
              this.birth.day.length > 1 &&
              this.birth.year.length > 3) {
            this.update()
          }
        }
      },
      deep: true,
    }
  },
  computed: mapGetters(['userInfo']),
  async mounted() {
    this.getUserInfo();
  },
  methods: {
    getUserInfo: function () {
      this.$store.dispatch('getUserInfo').then(() => {
        this.data.name = this.userInfo.name
        this.data.phone = this.userInfo.phone.formatter()
        this.data.last_name = this.userInfo.last_name
        this.data.middle_name = this.userInfo.middle_name
        this.data.email = this.userInfo.email
        this.data.gender = this.userInfo.gender
        this.data.birth_date = this.userInfo.birth_date
        this.data.adv_messages = this.userInfo.adv_messages
        this.photo = this.userInfo.photo
        if (this.userInfo.birth_date) {
          let birth_date = this.userInfo.birth_date.split('-')
          this.birth.year = birth_date[0]
          this.birth.day = birth_date[2]
          this.birth.month = birth_date[1]
        }
        this.cards = this.userInfo.cards
        this.$nextTick(() => {
          this.loading = false
        })
      });
    },
    update: function () {
      // eslint-disable-next-line no-undef
      this.$store.dispatch('updateUserInfo', _.omitBy(this.data, _.isNil)).then((res) => {
        if (res.data.result === 'ok') {
          this.$toast.success('Информация успешно сохранена')
        }
      })
    },
    updateImage: function () {
      this.$store.dispatch('updateUserImage', this.file).then((res) => {
        this.photo = res.data.data.url;
      });
    },
    handleFileUpload() {
      this.file = this.$refs.file.files[0];
      this.updateImage();
    },
    switchGender: function (val) {
      this.data.gender = val;
      this.update();
    },
    close: function () {
      this.$root.$emit('triggerProfileTab', 'myData');
    },
  }
}
</script>

<style scoped>
.form-group.birthday select {
    background: #F7F7F7;
    border: none
}
</style>
