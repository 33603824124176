<template>
  <div class="cancel-order-modal modal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <div class="close pointer" @click="close">
            <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g filter="url(#filter0_b_631_2916)">
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M13 26C20.1797 26 26 20.1797 26 13C26 5.8203 20.1797 0 13 0C5.8203 0 0 5.8203 0 13C0 20.1797 5.8203 26 13 26Z"
                      fill="#F5F5F5"/>
              </g>
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M17.8754 8.34029C17.466 7.93081 16.8065 7.9264 16.4024 8.33045L12.9336 11.7993L9.54098 8.40673C9.1315 7.99725 8.46754 7.99731 8.05799 8.40686C7.64844 8.81641 7.64838 9.48036 8.05787 9.88984L11.4504 13.2824L8.1146 16.6183C7.71056 17.0223 7.71496 17.6818 8.12445 18.0913C8.53393 18.5008 9.19342 18.5052 9.59747 18.1011L12.9333 14.7653L16.326 18.158C16.7355 18.5675 17.3994 18.5674 17.809 18.1579C18.2185 17.7483 18.2186 17.0844 17.8091 16.6749L14.4164 13.2822L17.8853 9.81331C18.2893 9.40927 18.2849 8.74978 17.8754 8.34029Z"
                    fill="black"/>
              <defs>
                <filter id="filter0_b_631_2916" x="-45.7896" y="-45.7896" width="117.579" height="117.579"
                        filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                  <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                  <feGaussianBlur in="BackgroundImage" stdDeviation="22.8948"/>
                  <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_631_2916"/>
                  <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_631_2916" result="shape"/>
                </filter>
              </defs>
            </svg>
          </div>
        </div>
        <div class="modal-body">
          <svg width="97" height="75" viewBox="0 0 97 75" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5 37.5L37.625 70L92 5" stroke="#FED42B" stroke-width="10" stroke-linecap="round"
                  stroke-linejoin="round"/>
          </svg>
          <h1>Бронирование отменено</h1>
          <p>Деньги автоматически поступят вам на карту, с которой была произведена оплата.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'success-cancel-order-modal',
  data: function () {
    return {}
  },
  async mounted() {
  },
  methods: {
    close: function () {
      this.$root.$emit('openModal', 'successCancelOrder');
    },
  }
}
</script>

<style scoped>
.cancel-order-modal .modal-dialog .modal-body h1 {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  margin-top: 30px;
}

.cancel-order-modal .modal-dialog .modal-body p {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  max-width: 335px;
  margin: 30px 0 30px 0;
  text-align: center;
}

.buttons {
  display: flex;
  justify-content: space-between;
  width: 335px;
  padding-bottom: 20px;
}

.buttons .btn {
  width: 160px;
  height: 50px;
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
  color: #000000;
}

</style>
