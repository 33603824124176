<template>
  <transition name="fade">
    <div class="search-result" v-if="this.$store.state.searchTrigger">
      <div class="result-list" v-if="regions.length > 0 || hotels.length > 0">
        <template v-if="regions.length > 0">
          <div class="list-label">Регион</div>
          <div class="result-list-item" v-for="region in regions" :key="region.id"
               @click="selectAutoCompete(1, region)">
            {{ region.name }}
          </div>
        </template>
        <template v-if="hotels.length > 0">
          <div class="list-label">Отели</div>
          <div class="result-list-item" v-for="hotel in hotels" :key="hotel.id + 1"
               @click="selectAutoCompete(2, hotel)">
            {{ hotel.name }}
          </div>
        </template>
      </div>
      <div class="result-list" v-else>
       <div class="result-empty">
         Попробуйте найти что-то другое
       </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'AutoCompete',
  props: {
    regions: Array,
    hotels: Array
  },
  async mounted() {
  },
  methods: {
    selectAutoCompete: function (type, val) {
      this.$store.state.autoCompleteMobile = false
      this.$store.state.searchTrigger = false
      this.$root.$emit('autocomplete', {id: val.id, name: val.name, type: type})
    },
  }
}
</script>
