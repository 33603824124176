import axios from 'axios'

export default {
    state: {
        aboutNumber: {}
    },
    getters: {
        getAboutNumber: state => state.aboutNumber
    },
    actions: {
        toursSearch(ctx, data) {
            return new Promise((resolve, reject) => {
                axios.get('tours/search?text=' + data.text
                    + '&from=' + data.from
                    + '&type=' + data.type
                    + '&arrivalDate=' + data.dateStart
                    + '&departureDate=' + data.dateEnd
                    + '&adults=' + data.adults
                    + '&children=' + data.children).then(response => {
                    resolve(response);
                }).catch(error => {
                    reject(error);
                });
            });
        },
        setAboutNumber(ctx, data) {
            ctx.commit('setStateAboutNumber', data)
        }
    },
    mutations: {
        setStateAboutNumber(state, data) {
            state.aboutNumber = data;
        }
    }
}
