import axios from 'axios'
import Vue from "vue";

export default {
    state: {},
    getters: {},
    actions: {
        sendConnectHotel(ctx, data) {
            axios.defaults.baseURL = 'https://apiv2.alamat.ru/api';
            return new Promise((resolve, reject) => {
                axios.post('/partners/connect', data).then(response => {
                    resolve(response);
                }).catch(error => {
                    Vue.$toast.error(error.response.data.message)
                    reject(error);
                });
            });
        },
    },
    mutations: {}
}
