/* import components */

import Vue from "vue";
import moment from 'moment';
import store from '../../store/store';

require('../../components/Plugins/Toast/Toast')
require('../../components/Plugins/Datepicker/Datepicker')
require('../../components/Plugins/Buttons/Loading/Ladda')
require('../../components/Plugins/YandexMetrika/YandexMetrika')

// Получение кол-во кроватей

export function getCountBad(count) {
    return count + ' ' + this.declination(count, ['кровать', 'кровати', 'кроватей']);
}

// Склонение слов

export function declination(number, words) {
    let n = Math.abs(number);
    n %= 100;
    if (n >= 5 && n <= 20) {
        return words[2];
    }
    n %= 10;
    if (n === 1) {
        return words[0];
    }
    if (n >= 2 && n <= 4) {
        return words[1];
    }
    return words[2];
}

// Разница в днях между датами

export function diffDates(date1, date2) {
    const a = moment(date1);
    const b = moment(date2);
    return b.diff(a, 'days');
}

// Получить все род. классы при клике на элемент

export function getPath(e) {
    let paths = e.composedPath();
    let newArr = [];

    paths.forEach((val) => {
        if (val.className && typeof val.className !== 'undefined' && typeof val.className !== 'object') {
            if (val.className.indexOf(' ') !== -1) {
                let newArr2 = val.className.split(' ');
                newArr2.forEach((val2) => {
                    newArr.push(val2);
                });
                return true;
            }
            newArr.push(val.className);
        }
    });

    return newArr;
}

// Скроллинг к элементу

export function scrollToElement(el) {
    const scrollDiv = document.getElementById(el).offsetTop;
    window.scrollTo({top: scrollDiv, behavior: 'smooth'});
}

// Проверка авторизации клиента

export function checkAuth() {
    return store.getters.isLoggedIn;
}

// Упорядочивание баннеров на главной странице

export function orderingBanners(banners) {
    let countUnique = getCountUniqueInArray(banners, 'place_id');
    let newArr = [];

    for (let i = 1; i <= countUnique; i++) {
        newArr[i] = [];
    }

    banners.map((item) => {
        newArr[item.place_id].push(item);
    });

    return newArr;
}

export function getCountUniqueInArray(array, field) {
    let newArr = array.reduce((seed, current) => {
        return Object.assign(seed, {
            [current[field]]: current
        });
    }, {});
    return Object.keys(newArr).length;
}

// Блокировка дат в календаре

export function disabledDate(date, currentValue) {
    let currentDate = currentValue[0]
    let dateNow = new Date(currentDate);
    dateNow.setDate(dateNow.getDate() + 30);
    const today = new Date().setHours(0, 0, 0, 0)
    return date > dateNow || date < new Date(today);
}

// Транслитерация RU - EN

export function transliteration(word) {
    if (word && word.length > 0) {
        let answer = '';
        let converter = {
            'а': 'a', 'б': 'b', 'в': 'v', 'г': 'g', 'д': 'd',
            'е': 'e', 'ё': 'e', 'ж': 'zh', 'з': 'z', 'и': 'i',
            'й': 'y', 'к': 'k', 'л': 'l', 'м': 'm', 'н': 'n',
            'о': 'o', 'п': 'p', 'р': 'r', 'с': 's', 'т': 't',
            'у': 'u', 'ф': 'f', 'х': 'h', 'ц': 'c', 'ч': 'ch',
            'ш': 'sh', 'щ': 'sch', 'ь': '', 'ы': 'y', 'ъ': '',
            'э': 'e', 'ю': 'yu', 'я': 'ya',

            'А': 'A', 'Б': 'B', 'В': 'V', 'Г': 'G', 'Д': 'D',
            'Е': 'E', 'Ё': 'E', 'Ж': 'Zh', 'З': 'Z', 'И': 'I',
            'Й': 'Y', 'К': 'K', 'Л': 'L', 'М': 'M', 'Н': 'N',
            'О': 'O', 'П': 'P', 'Р': 'R', 'С': 'S', 'Т': 'T',
            'У': 'U', 'Ф': 'F', 'Х': 'H', 'Ц': 'C', 'Ч': 'Ch',
            'Ш': 'Sh', 'Щ': 'Sch', 'Ь': '', 'Ы': 'Y', 'Ъ': '',
            'Э': 'E', 'Ю': 'Yu', 'Я': 'Ya'
        };

        for (let i = 0; i < word.length; ++i) {
            if (converter[word[i]] === undefined) {
                answer += word[i];
            } else {
                answer += converter[word[i]];
            }
        }

        return answer.toUpperCase();
    }
}

Object.defineProperty(String.prototype, 'formatter', {
    value: function () {
        let phone = this
        phone = phone.split('')
        phone.splice(2, 0, '-');
        phone.splice(6, 0, '-');
        phone.splice(10, 0, '-');
        phone.splice(13, 0, '-');
        return phone.join('')
    },
    enumerable: false
});


Vue.directive('transliteration', {
    update(el) {
        if (el.value.length > 0) {
            const event = new Event("input", {bubbles: true});
            el.value = transliteration(el.value);
            el.dispatchEvent(event);
        }
    }
});

Vue.directive('number', {
    update(el) {
        if (el.value.length > 0) {
            const event = new Event("input", {bubbles: true});
            el.value = el.value.replace(/[^0-9]/g, "");
            el.dispatchEvent(event);
        }
    }
});
