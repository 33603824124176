<template>
    <form class="form-inputs">
        <div class="form-group text">
            <input
                    type="text"
                    placeholder="Откуда"
                    v-model="data.from.text"
                    @keyup="triggerSearchFrom">
            <input-loader :loading="fromLoading" :css-top="23" :css-right="15"/>

            <auto-complete-avia :cities="autocomplete.cities" :type="'from'" v-if="this.$store.state.searchTriggerFrom"/>
        </div>

        <div class="form-group text">
            <input
                    type="text"
                    placeholder="Куда"
                    v-model="data.to.text"
                    @keyup="triggerSearchTo">
            <input-loader :loading="toLoading" :css-top="23" :css-right="15"/>
            <auto-complete-avia :cities="autocomplete.cities" :type="'to'" v-if="this.$store.state.searchTrigger"/>
        </div>

        <div class="form-group calendar">
            <date-picker v-model="data.date" placeholder="Дата вылета"
                         :format="'DD-MM-YYYY'"
                         :value-type="'YYYY-MM-DD'" :disabled-date="$core.disabledDate"
                         :clearable="false"/>
        </div>

        <div class="form-group calendar">
            <date-picker v-model="data.returnDate" placeholder="Обратно"
                         :format="'DD-MM-YYYY'"
                         :value-type="'YYYY-MM-DD'" :disabled-date="$core.disabledDate"
                         :clearable="false"/>
        </div>

        <div class="form-group placement">
            <input type="text" class="search-room" v-model="guests" :readonly="true"
                   @click="$store.state.selectPlacement = !$store.state.selectPlacement">
            <div class="svg">
                <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        width="17px" height="9px">
                    <defs>
                        <filter id="Filter_0">
                            <feFlood flood-color="rgb(47, 172, 187)" flood-opacity="1"
                                     result="floodOut"/>
                            <feComposite operator="atop" in="floodOut" in2="SourceGraphic"
                                         result="compOut"/>
                            <feBlend mode="normal" in="compOut" in2="SourceGraphic"/>
                        </filter>

                    </defs>
                    <g filter="url(#Filter_0)">
                        <path fill-rule="evenodd" fill="rgb(95, 95, 95)"
                              d="M0.909,0.394 L16.561,0.383 L8.751,8.571 L0.909,0.394 Z"/>
                    </g>
                </svg>
            </div>
            <transition name="fade">
                <div class="select-placement" v-if="this.$store.state.selectPlacement">
                    <div class="placement-item">
                        <div class="placement-item-titles">
                            <div class="placement-item-title">Взрослые</div>
                            <div class="placement-item-sub-title">старше 17 лет</div>
                        </div>
                        <div class="placement-item-counter">
                            <button type="button" class="btn" @click="updateCounter('adults', 0)"
                                    :disabled="data.adults < 2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                     class="a2ka">
                                    <path fill="currentColor"
                                          d="M3 7a1 1 0 0 0 0 2h10a1 1 0 1 0 0-2H3Z"></path>
                                </svg>
                            </button>
                            <input class="counter" v-model="data.adults" :disabled="true"/>
                            <button type="button" class="btn" @click="updateCounter('adults', 1)">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                     class="a2ka">
                                    <path fill="currentColor"
                                          d="M9 3a1 1 0 0 0-2 0v4H3a1 1 0 0 0 0 2h4v4a1 1 0 1 0 2 0V9h4a1 1 0 1 0 0-2H9V3Z"></path>
                                </svg>
                            </button>
                        </div>
                    </div>
                    <div class="placement-item">
                        <div class="placement-item-titles">
                            <div class="placement-item-title">Дети</div>
                            <div class="placement-item-sub-title">от 0 до 17 лет</div>
                        </div>
                        <div class="placement-item-counter">
                            <button type="button" class="btn" @click="updateCounter('children', 0)"
                                    :disabled="data.children < 1">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                     class="a2ka">
                                    <path fill="currentColor"
                                          d="M3 7a1 1 0 0 0 0 2h10a1 1 0 1 0 0-2H3Z"></path>
                                </svg>
                            </button>
                            <input class="counter" v-model="data.children" :disabled="true"/>
                            <button type="button" class="btn" @click="updateCounter('children', 1)">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                     class="a2ka">
                                    <path fill="currentColor"
                                          d="M9 3a1 1 0 0 0-2 0v4H3a1 1 0 0 0 0 2h4v4a1 1 0 1 0 2 0V9h4a1 1 0 1 0 0-2H9V3Z"></path>
                                </svg>
                            </button>
                        </div>
                    </div>
                    <div class="placement-item child-age" v-if="data.children > 0">
                        <multiselect
                                v-for="index in data.children"
                                :key="index"
                                v-model="childrenAges[index - 1]"
                                track-by="name"
                                label="name"
                                :options="options"
                                :searchable="false"
                                :close-on-select="true"
                                :show-labels="false"
                                placeholder="Укажите возраст"
                                :openDirection="'below'"
                        ></multiselect>
                    </div>
                </div>
            </transition>
        </div>

    </form>
</template>

<script>
import InputLoader from "@/components/loaders/input/loader.vue";
import AutoCompleteAvia from "@/components/AutoCompete/avia/AutoCompete.vue";

export default {
    name: 'header-avia-search',
    components: {AutoCompleteAvia, InputLoader},
    data: function () {
        return {
            data: {
                from: {
                    text: '',
                    code: '',
                },
                to: {
                    text: '',
                    code: '',
                },
                date: this.$moment().add(1, 'days').format('Y-MM-DD'),
                returnDate: null,
                adults: 1,
                children: 0,
                ages: [],
            },
            autocomplete: {
                cities: []
            },
            fromLoading: false,
            toLoading: false,
            timer: null,
            childrenAges: [],
            options: [
                {name: 'До года', code: '0'},
                {name: '1 год', code: '1'},
                {name: '2 года', code: '2'},
                {name: '3 года', code: '3'},
                {name: '4 года', code: '4'},
                {name: '5 лет', code: '5'},
                {name: '6 лет', code: '6'},
                {name: '7 лет', code: '7'},
                {name: '8 лет', code: '8'},
                {name: '9 лет', code: '9'},
                {name: '10 лет', code: '10'},
                {name: '11 лет', code: '11'},
                {name: '12 лет', code: '12'},
                {name: '13 лет', code: '13'},
                {name: '14 лет', code: '14'},
                {name: '15 лет', code: '15'},
                {name: '16 лет', code: '16'},
                {name: '17 лет', code: '17'}
            ],
        }
    },
    watch: {
        childrenAges(newValue) {
            this.data.ages = []
            Object.keys(newValue).forEach((index) => {
                this.data.ages.push(this.childrenAges[index].code)
            })
        }
    },
    computed: {
        guests: function () {
            let count = this.data.adults + this.data.children;
            return count + ' ' + this.$core.declination(count, ['пассажир', 'пассажира', 'пассажиров']);
        },
    },
    async mounted() {
        this.$root.$on('autocomplete', (val) => {
            this.setAutoComplete(val)
        });
    },
    methods: {
        setAutoComplete: function (val) {
            this.data[val.type].text = val.name + ' (' + val.code + ')'
            this.data[val.type].code = val.code
        },
        updateCounter: function (variable, type) {
            if (type === 0 && variable === 'adults' && this.data[variable] > 1) {
                this.data[variable]--;
            } else if (type === 0 && variable === 'children' && this.data[variable] >= 1) {
                this.data[variable]--;
                this.childrenAges.pop();
            } else if (variable === 'children' && this.data[variable] < 4) {
                this.data[variable]++;
            } else if (variable !== 'children' && this.data[variable] < 6) {
                this.data[variable]++;
            }
        },
        triggerSearchFrom: function () {
            if (!this.timer) {
                if (this.data.from.text.length > 1) {
                    this.$store.state.searchTrigger = false
                    this.fromLoading = true
                    this.timer = setTimeout(() => {
                        this.$store.dispatch('getAutoCompleteAirport', {
                            query: this.data.from.text
                        }).then((res) => {
                            this.autocomplete.cities = res.data.data.cities
                            this.$store.state.searchTriggerFrom = true
                            this.timer = null
                            this.fromLoading = false
                        }).catch(() => {
                            this.$toast.error('Ошибка... Повторите поиск')
                            this.timer = null
                            this.fromLoading = false
                            this.$store.state.searchTriggerFrom = false
                        })
                    }, 500)
                }
            }
        },
        triggerSearchTo: function () {
            if (!this.timer) {
                if (this.data.to.text.length > 1) {
                    this.$store.state.searchTriggerFrom = false
                    this.toLoading = true
                    this.timer = setTimeout(() => {
                        this.$store.dispatch('getAutoCompleteAirport', {
                            query: this.data.to.text
                        }).then((res) => {
                            this.autocomplete.cities = res.data.data.cities
                            this.$store.state.searchTrigger = true
                            this.timer = null
                            this.toLoading = false
                        }).catch(() => {
                            this.$toast.error('Ошибка... Повторите поиск')
                            this.timer = null
                            this.toLoading = false
                            this.$store.state.searchTrigger = false
                        })
                    }, 500)
                }
            }
        },
        startSearch: function () {
            if (this.data.from.code.length <= 0 || this.data.to.code.length <= 0) {
                this.$toast.error('Выберите откуда и куда полетите')
                return false
            }

            this.$router.push({
                name: 'avia-catalog',
                query: {
                    tFrom: this.data.from.text,
                    from: this.data.from.code,
                    to: this.data.to.code,
                    tTo: this.data.to.text,
                    date: this.data.date,
                    returnDate: this.data.returnDate,
                    adults: this.data.adults,
                    children: this.data.ages,
                }
            })
        },
    }
}
</script>