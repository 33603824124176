<template>
  <div class="toggle-top">
    <div class="circle-top">
      <svg width="14" height="9" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.355112 6.00899C-0.118458 6.48255 -0.118458 7.25036 0.355112 7.72393C0.828681 8.19749 1.59649 8.19749 2.07006 7.72393L6.86671 2.92729L11.661 7.72156C12.1333 8.19387 12.899 8.19387 13.3714 7.72156C13.8437 7.24925 13.8437 6.48348 13.3714 6.01117L7.71443 0.354236C7.24212 -0.118075 6.47635 -0.118075 6.00405 0.354236C5.94458 0.413703 5.8926 0.477821 5.84811 0.54542C5.78694 0.587274 5.72876 0.635357 5.67445 0.689668L0.355112 6.00899Z" fill="black"/>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: 'toggle-top',
  data: function () {
    return {

    }
  },
  mounted() {

  },
  computed: {},
  methods: {

  }
}
</script>
