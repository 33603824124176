<template>
  <div class="loader" :style="{'margin-top': this.marginTop > 0 ? this.marginTop + 'px' : 0 }">
    <img src="@/assets/images/loaders/preloader.png" :style="style">
  </div>
</template>

<script>
export default {
  name: 'loader',
  props: {
    marginTop: Number,
  },
  data: function () {
    return {
      isShow: true,
      turn: 0.1,
      style: '',
    }
  },
  mounted() {
    this.rotation();
  },
  methods: {
    rotation: function () {
      setTimeout(() => {
        this.turn += 0.1;
        this.style = "transform: rotate(" + this.turn + "turn)";
        this.rotation();
      }, 100);
    },
  }
}
</script>

<style scoped>
.loader {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
}

.loader img {
  overflow: auto;
  margin: 50px 0 50px 0;
  width: 15%;
}

@media screen and (max-width: 768px) {
  .loader img {
    width: 30%;
  }
}

</style>
