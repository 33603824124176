<template>
    <transition name="fade">
        <div class="search-result" v-if="this.$store.state.searchTrigger || this.$store.state.searchTriggerFrom">
            <div class="result-list" v-if="cities.length > 0">
                <template v-for="city in cities">
                    <div class="list-label" :key="city.id">{{ city.city_name }}</div>
                    <template v-if="city.airports">
                        <div class="result-list-item" v-for="airport in city.airports" :key="airport.id"
                             @click="selectAutoCompete(airport)">
                            {{ airport.name }}
                        </div>
                    </template>
                </template>
            </div>
            <div class="result-list" v-else>
                <div class="result-empty">
                    Попробуйте найти что-то другое
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    name: 'AutoCompleteAvia',
    props: {
        cities: Array,
        type: String
    },
    methods: {
        selectAutoCompete: function (val) {
            this.$root.$emit('clearAutoCompleteMobileQuery')
            this.$store.state.avia.autoCompleteMobile = false
            this.$store.state.searchTriggerFrom = false
            this.$store.state.searchTrigger = false
            this.$root.$emit('autocomplete', {code: val.code, name: val.name, type: this.type})
        },
    }
}
</script>
