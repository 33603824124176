<template>
  <div class="create-passenger-modal modal">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <div class="close pointer" @click="close">
            <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g filter="url(#filter0_b_631_2916)">
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M13 26C20.1797 26 26 20.1797 26 13C26 5.8203 20.1797 0 13 0C5.8203 0 0 5.8203 0 13C0 20.1797 5.8203 26 13 26Z"
                      fill="#F5F5F5"/>
              </g>
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M17.8754 8.34029C17.466 7.93081 16.8065 7.9264 16.4024 8.33045L12.9336 11.7993L9.54098 8.40673C9.1315 7.99725 8.46754 7.99731 8.05799 8.40686C7.64844 8.81641 7.64838 9.48036 8.05787 9.88984L11.4504 13.2824L8.1146 16.6183C7.71056 17.0223 7.71496 17.6818 8.12445 18.0913C8.53393 18.5008 9.19342 18.5052 9.59747 18.1011L12.9333 14.7653L16.326 18.158C16.7355 18.5675 17.3994 18.5674 17.809 18.1579C18.2185 17.7483 18.2186 17.0844 17.8091 16.6749L14.4164 13.2822L17.8853 9.81331C18.2893 9.40927 18.2849 8.74978 17.8754 8.34029Z"
                    fill="black"/>
              <defs>
                <filter id="filter0_b_631_2916" x="-45.7896" y="-45.7896" width="117.579" height="117.579"
                        filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                  <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                  <feGaussianBlur in="BackgroundImage" stdDeviation="22.8948"/>
                  <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_631_2916"/>
                  <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_631_2916" result="shape"/>
                </filter>
              </defs>
            </svg>
          </div>
        </div>
        <div class="modal-body-df">
          <h1 class="title-modal">Данные пассажира</h1>
          <span
              class="title-help-modal">Если есть загранпаспорт, укажите имя как в нём. В аэропорту сверяют данные</span>
          <div class="block">
            <div class="form-group">
              <label class="label">Фамилия</label>
              <input type="text" placeholder="Фамилия на латинице" v-model="data.surname"
                     @focusout="transliteration('surname')"/>
            </div>
            <div class="form-group">
              <label class="label">Имя</label>
              <input type="text" placeholder="Имя на латинице" v-model="data.name" @focusout="transliteration('name')"/>
            </div>
            <div class="form-group">
              <label class="label">Отчество</label>
              <input type="text" placeholder="Отчество на латинице" v-model="data.lastname"
                     @focusout="transliteration('lastname')"/>
              <div class="checkbox-custom">
                <input type="checkbox" @v-model="data.notLastName">
                <label>Нет отчества</label>
              </div>
            </div>
          </div>
          <div class="block">
            <div class="form-group">
              <label class="label">Дата рождения</label>
              <div class="input-flex">
                <input type="text" placeholder="12" v-model="day"/>
                <select v-model="month">
                  <option value="01">Январь</option>
                  <option value="02">Февраль</option>
                  <option value="03">Март</option>
                  <option value="04">Апрель</option>
                  <option value="05">Май</option>
                  <option value="06">Июнь</option>
                  <option value="07">Июль</option>
                  <option value="08">Август</option>
                  <option value="09">Сентябрь</option>
                  <option value="10">Октябрь</option>
                  <option value="11">Ноябрь</option>
                  <option value="12">Декабрь</option>
                </select>
                <input type="text" placeholder="2022" v-model="year">
              </div>
            </div>
            <div class="form-group">
              <label>Пол</label>
              <div class="sex-group">
                <div class="sex-item" :class="[data.sex == 1 ? 'active': '']" @click="switchSex(1)">Мужской</div>
                <div class="sex-item" :class="[data.sex == 2 ? 'active': '']" @click="switchSex(2)">Женский</div>
              </div>
            </div>
          </div>
          <h1 class="title-modal mt-3">Документ</h1>
          <div class="block">
            <div class="form-group">
              <label class="label">Гражданство</label>
              <select v-model="data.citizenship">
                <option value="1">Россия</option>
              </select>
            </div>
            <div class="form-group">
              <label class="label">Номер документа</label>
              <masked-input type="text" class="masked" mask="11 11 111111" v-model="data.numberDocument"
                            placeholder="Номер документа"/>
            </div>
          </div>
          <div class="checkbox-custom validity-period">
            <input type="checkbox" v-model="data.validityPeriod">
            <label>Срок действия</label>
          </div>
          <div class="block">
            <div class="form-group">
              <div class="input-flex">
                <input type="text" placeholder="12" v-model="data.validateDay"/>
                <select v-model="data.validateMonth">
                  <option value="01">Январь</option>
                  <option value="02">Февраль</option>
                  <option value="03">Март</option>
                  <option value="04">Апрель</option>
                  <option value="05">Май</option>
                  <option value="06">Июнь</option>
                  <option value="07">Июль</option>
                  <option value="08">Август</option>
                  <option value="09">Сентябрь</option>
                  <option value="10">Октябрь</option>
                  <option value="11">Ноябрь</option>
                  <option value="12">Декабрь</option>
                </select>
                <input type="text" placeholder="2022" v-model="data.validateYear">
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button class="btn btn-success" @click="validateData">Сохранить данные</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import maskedInput from "vue-masked-input";

export default {
  name: 'create-passenger-modal',
  components: {maskedInput},
  data: function () {
    return {
      validate: false,
      data: {
        surname: '',
        name: '',
        lastname: '',
        notLastName: '',
        sex: 1,
        citizenship: 1,
        numberDocument: '',
        validityPeriod: false,
        validateDay: null,
        validateMonth: '01',
        validateYear: null,
      },
      day: null,
      month: '01',
      year: null,
    }
  },
  async mounted() {
  },
  computed: {},
  methods: {
    saveData: function () {
      let data = {
        surname: this.data.surname,
        name: this.data.name,
        patronymic: this.data.lastname,
        birth_date: this.year + '-' + this.month + '-' + this.day,
        gender: this.data.sex,
        passport_number: this.data.numberDocument,
        passport_date: this.data.validateYear + '-' + this.data.validateMonth + '-' + this.data.validateDay,
      };
      this.$store.dispatch('createNotebook', data).then((res) => {
        if (res.data.result === 'ok') {
          this.close();
          this.$root.$emit('getUserInfo');
          this.$notify(res.data.message);
        } else {
          this.$notify({type: 'error', text: res.data.message});
        }
      });
    },
    transliteration: function (val) {
      this.data[val] = this.$core.transliteration(this.data[val]);
    },
    validateData: function () {
      this.validate = false;

      if (!this.data.validateDay || !this.data.validateYear || !this.day || !this.year) {
        this.validate = true;
        this.$notify({type: 'error', text: 'Заполните все данные'});
      }

      if (!this.validate) {
        this.saveData();
      }

    },
    switchSex: function (val) {
      if (val === 1) {
        this.data.sex = 1;
      } else {
        this.data.sex = 2;
      }
    },
    close: function () {
      this.$root.$emit('openModal', 'createPassenger');
    },
  }
}
</script>

<style scoped>
.create-passenger-modal .modal-body-df .title-modal {;
  font-weight: 500;
  font-size: 20px;
  line-height: 11px;
  color: #000000;
}

.create-passenger-modal .modal-body-df .title-help-modal {
  display: block;
  font-family: 'dinpro_light';
  font-weight: 300;
  font-size: 16px;
  line-height: 11px;
  color: #000000;
  margin-top: 15px;
}

.create-passenger-modal .modal-body-df .block {
  display: flex;
  justify-content: flex-start;
  flex-flow: row wrap;
  margin-top: 20px;
}

.create-passenger-modal .modal-body-df .block .form-group {
  width: 100%;
  max-width: 335px;
}

.create-passenger-modal .modal-body-df .block .form-group:not(:last-child) {
  margin-right: 30px;
}

.create-passenger-modal .modal-body-df .block .form-group input[type='text'] {
  border: none;
  background: #F7F7F7;
  border-radius: 8px;
  height: 52px;
}

.create-passenger-modal .modal-body-df .block .form-group .checkbox-custom {
  margin-top: 10px;
}

.create-passenger-modal .modal-body-df .block .form-group .checkbox-custom input, .create-passenger-modal .modal-body-df .validity-period input {
  border: 1px solid #9D9D9D;
  border-radius: 29px;
}

.create-passenger-modal .modal-body-df .validity-period {
  margin-top: 10px;
}

.create-passenger-modal .modal-body-df .checkbox-custom label {
  font-family: 'dinpro_light';
  font-size: 14px;
}

.create-passenger-modal .modal-footer .btn {
  background: #43B02A;
  border-radius: 10px;
  height: 40px;
  width: 239px;
}

</style>
