import Vue from 'vue';
import Router from 'vue-router';
import store from './store/store.js';

Vue.use(Router);

const router = new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'index',
            component: () => import('./views/index'),
            meta: {
                layout: 'main',
                requiresAuth: false,
                title: 'Главная',
            }
        },
        {
            path: '/hotels',
            name: 'hotels',
            component: () => import('./views/catalog'),
            meta: {
                layout: 'main',
                requiresAuth: false,
                title: 'Гостиницы',
            }
        },
        {
            path: '/hotel/:id',
            name: 'hotel',
            component: () => import('./views/hotel-card'),
            meta: {
                layout: 'main',
                requiresAuth: false,
                title: 'Карточка гостиницы',
            }
        },
        {
            path: '/favourites',
            name: 'favourites',
            component: () => import('./views/favourites'),
            meta: {
                layout: 'main',
                requiresAuth: true,
                title: 'Избранное',
            }
        },
        {
            path: '/profile',
            name: 'profile',
            component: () => import('./views/profile'),
            meta: {
                layout: 'main',
                requiresAuth: true,
                title: 'Личный кабинет',
            }
        },
        /* SERVICES */

        {
            path: '/services',
            name: 'services-catalog',
            component: () => import('./views/services/catalog'),
            meta: {
                layout: 'main',
                requiresAuth: false,
                title: 'Услуги',
            }
        },

        {
            path: '/services/:id',
            name: 'service-card',
            component: () => import('./views/services/card'),
            meta: {
                layout: 'main',
                requiresAuth: false,
                title: 'Карточка услуги',
            }
        },

        /* SERVICES END */


        /* AVIA */

        {
            path: '/avia',
            name: 'avia-catalog',
            component: () => import('./views/avia/catalog'),
            meta: {
                layout: 'main',
                requiresAuth: false,
                title: 'Авиабилеты',
            }
        },

        {
            path: '/avia/confirmation',
            name: 'avia-confirmation',
            component: () => import('./views/avia/confirmation.vue'),
            meta: {
                layout: 'main',
                requiresAuth: false,
                title: 'Оформление авиа',
            }
        },


        /* AVIA END */

        {
            path: '/tours',
            name: 'tours',
            component: () => import('./views/tours-dev.vue'),
            meta: {
                layout: 'main',
                requiresAuth: false,
                title: 'Туры'
            }
        },
        {
            path: '/confirmation',
            name: 'confirmation',
            component: () => import('./views/confirmation'),
            meta: {
                layout: 'main',
                requiresAuth: false,
                title: 'Подтверждение бронирования'
            }
        },
        {
            path: '/order/success/:id',
            name: 'order-success',
            component: () => import('./views/bookings/success.vue'),
            meta: {
                layout: 'main',
                requiresAuth: false,
                title: 'Успешное оформление'
            }
        },
        {
            path: '/order/error/:id',
            name: 'order-error',
            component: () => import('./views/bookings/error.vue'),
            meta: {
                layout: 'main',
                requiresAuth: false,
                title: 'Ошибка оформления'
            }
        },
        {
            path: '/region/:name',
            name: 'region',
            component: () => import('./views/region'),
            meta: {
                layout: 'main',
                requiresAuth: false,
                title: 'Регион'
            }
        },
        {
            path: '/offer',
            name: 'offer',
            component: () => import('./views/static/offer'),
            meta: {
                layout: 'main',
                requiresAuth: false,
                title: 'Договор оферта'
            }
        },
        {
            path: '/approval',
            name: 'approval',
            component: () => import('./views/static/approval'),
            meta: {
                layout: 'main',
                requiresAuth: false,
                title: 'АГЕНТСКИЙ ДОГОВОР-ОФЕРТА ООО «АЛАМАТ»'
            }
        },
        {
            path: '/politics',
            name: 'politics',
            component: () => import('./views/static/politics.vue'),
            meta: {
                layout: 'main',
                requiresAuth: false,
                title: 'Политика конфиденциальности'
            }
        },
        {
            path: '/connect',
            name: 'connect',
            component: () => import('./views/static/connect-hotel'),
            meta: {
                layout: 'main',
                requiresAuth: false,
                title: 'Подключить отель'
            }
        },
        {
            path: '/about',
            name: 'about',
            component: () => import('./views/static/about-us'),
            meta: {
                layout: 'main',
                requiresAuth: false,
                title: 'О компании'
            }
        },
        {
            path: '/processing',
            name: 'processing',
            component: () => import('./views/static/processing'),
            meta: {
                layout: 'main',
                requiresAuth: false,
                title: 'Страница в разработке'
            }
        },
        {
            path: '/contact',
            name: 'contact',
            component: () => import('./views/static/contact'),
            meta: {
                layout: 'main',
                requiresAuth: false,
                title: 'Контакты'
            }
        },
        {
            path: '/payment',
            name: 'payment',
            component: () => import('./views/static/payment.vue'),
            meta: {
                layout: 'main',
                requiresAuth: false,
                title: 'Оплата'
            }
        },
        {
            path: '*',
            name: 'except',
            component: () => import('./views/static/not-found'),
            meta: {
                layout: 'main',
                requiresAuth: false,
                title: 'Страница не найдена'
            }
        },
    ],
    scrollBehavior(to, from) {
        if (to.path !== from.path) {
            return { x: 0, y: 0 };
        }
    }
})

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (store.getters.isLoggedIn) {
            next()
            return
        }
        next('/')
    } else {
        next()
    }
});

export default router
