<template>
    <header :class="[this.$route.name !== 'index' ? 'formatter' : '']">
        <div class="container">
            <div class="new-header">

                <div class="new-header-mobile" v-if="this.$store.state.width <= 992">

                    <div class="user" @click="triggerProfile">
                        <svg width="46" height="35" viewBox="0 0 46 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <mask id="mask0_193_466" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0"
                                  width="46"
                                  height="35">
                                <ellipse cx="22.5556" cy="17.5" rx="22.5556" ry="17.5" fill="#FFFFFF"/>
                            </mask>
                            <g mask="url(#mask0_193_466)">
                                <ellipse cx="23.2797" cy="33.4812" rx="16.0395" ry="15.5" fill="#FFFFFF"/>
                                <ellipse cx="22.9087" cy="7.87744" rx="7.51852" ry="7.5" fill="#FFFFFF"/>
                            </g>
                        </svg>
                    </div>

                    <router-link class="logo" to="/">
                        <img src="@/assets/images/header/logo-new.png" alt="">
                    </router-link>

                    <div class="toggle-menu" @click="triggerMenu">
                        <div class="svg">
                            <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                    width="53px" height="53px">
                                <image x="0px" y="0px" width="40px" height="53px"
                                       xlink:href="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAADUAAAA1CAQAAABL72v7AAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QA/4ePzL8AAAAHdElNRQfmCBoBLDb0VDoFAAAETklEQVRYw+2YX2RcWRzHv723hmEYhmEYQhjKMIRhKCEswxDC0qewhKWUUkIIIZQlT6WEEsqylFKydqUSu7oSeVgbE6nEViOVlUokuhKzsiYmpr77cO8995x7z+/svLQs+z0P9+Sez5zflfv7d+4NIlQGTYwhjyts4Gf8DUm30UQZwK9Yw5nA1NBCBcAefsR7dZfBaPAtYx1znLCMMlc1qstpC5PnM43p8xtmgpVgeZZ9JvU0tUmLnRS1zrzBNHicYnZZjkx9SbvmjU0qvLRSyxpTsBgiyR36BJjhkWDqkiVtmx8oqaWYxyJzlwDHKeu+9rx9kXoWMj7PRWaT8NCArDHN73yRGg2vVRQcfpvxUHSYitdKDqqUotPyUfTwlwO4ULNBKBcDXHjYcyxvq9nWANQbXInMHq7AvPgy+7yleeCm+MonFfOdyMwHcTUjLC8acTUq+GCbvhZ7XStzxFyULb61umc2kS3uW6hDDhnMhMVYh404MYGTRtLpcU571njcNjIlucRcirnF3wxmNUhLxA2V2YtooY4R7GMbL3EivN4sWmigjjNsYwOvBcduoY46rrGNLfwS3Y5NfXJ5n83S5zR1U5sPoQkfwAb2Hb8ooIk8gH1siEwOTRQBnGANH9Xd0G9GuK55zS6bQhXWw+KUX1ur8GP2NEefM6vwpLYYaSa1SZ2nKepFtFE4hnmQYsJKDYJjFkMkOWVsUrIYSuaUHHetzGpgyucO7To3+oYlShpRzKzI3CHAUcq6pzbJCtmNJJ8o6khkXhGeVmnTitdGkRWp8fBawZBjJ99zLOu1d3gAquxgfBQ9Rx8LLSZc1PUADHDtCSkz0J5llla0wzvt0ZI6wQVYEFydJOuaB74VqQcD9IqPgrhaEJZXjLiSeuBDLYhHhErdYSkwlTGSUqQDFhPZYtFCdbWoAsFpC9PnRJyYssbpIYiDUsIQCM4l/tkHrKWYqURvfxo12noxX2KbPbb5QuvCk6PCBbZ5zt+5wqlE/otGkfPc5DEP+YrTcUvwfxX+75i6afxVQwbAa0fUA0AlLPiuRFRGCcB7/KndC/0jw4eq9HW4aGklo3odZY0elzlsZVpsK1dfj8MhuFRT1fOQX1iceDlBXfJugsmlSqjqlIN+4IMlxnscNTbJas+q657G+EIWfBSZWhGy24HRt88LVDfqyQl+RUkNAqxR1h3teeUj9YKidkXmOeGh6fCjcTVrOI7UEVVGTWQmAA8Vh6m4Gag6nT+QqynIouipcm1T/AHh4wCUOxp9D384lt+pmauLfxNeXTtd4MzD9w4gXtsy4t7Uy/B65vgOsAaASJXFSDtGXD0QqA4LipE+IvVZDeKqaO1KO4kK6/OnfwkIEHxqZWbixFRJRcRpcCpPJJ1ksHe1bxZRLn2SMjRrFvwsp5W5Iz5MfFDU+6ao5fnAJVasTJMrYQ9yyedxm5M+Q2UFI/qoau9HGsNawgJB/APKHMBksLIKvAAAAABJRU5ErkJggg=="/>
                            </svg>
                        </div>
                    </div>

                </div>

                <div class="new-header-fast-link">

                    <div class="fast-links-block">
                        <router-link to="/connect">Добавить свой отель</router-link>
                        <router-link to="/contact">Контакты</router-link>
                        <!--
                        <router-link to="/processing">Офисы продаж</router-link>
                        -->
                        <router-link to="/about">О нас</router-link>
                    </div>

                    <div class="payment-info-image">
                        <img src="@/assets/images/header/payment-info-image.png">
                    </div>

                </div>

                <div class="new-header-middle">

                    <router-link class="logo" to="/">
                        <img src="@/assets/images/header/logo-new.png">
                        <span>Туроператор и агрегатор по бронированию</span>
                    </router-link>

                    <div class="new-header-menu">

                        <div class="menu-item" @click="openHotels">
                            <div class="svg">
                                <img src="@/assets/images/header/hotel.svg">
                            </div>
                            <span>Отели</span>
                        </div>

                      <!--
                        <a class="menu-item" href="/tours">
                            <div class="svg">
                                <img src="@/assets/images/header/tours.svg" alt="">
                            </div>
                            <span>Туры</span>
                        </a>
                      -->
                        <a class="menu-item" @click="openFavourites">
                            <div class="svg">
                                <img src="@/assets/images/header/favorites.svg">
                            </div>
                            <span>Избранное</span>
                        </a>

                        <div class="menu-item" @click="triggerProfile">
                            <div class="svg">
                                <img src="@/assets/images/header/personal.svg">
                            </div>
                            <span>Личный кабинет</span>
                        </div>

                    </div>

                    <div class="phones">
                        <div class="phone">8 (495) 225-81-11</div>
                        <div class="phone">8 (800) 777-59-26</div>
                        <span class="work-time">c 9:00 до 20:00, без выходных</span>
                    </div>
                    <!--
                    <div class="toggle-menu" @click="triggerMenu">
                        <div class="svg">
                            <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                    width="53px" height="53px">
                                <image x="0px" y="0px" width="53px" height="53px"
                                       xlink:href="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAADUAAAA1CAQAAABL72v7AAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QA/4ePzL8AAAAHdElNRQfmCBoBLDb0VDoFAAAETklEQVRYw+2YX2RcWRzHv723hmEYhmEYQhjKMIRhKCEswxDC0qewhKWUUkIIIZQlT6WEEsqylFKydqUSu7oSeVgbE6nEViOVlUokuhKzsiYmpr77cO8995x7z+/svLQs+z0P9+Sez5zflfv7d+4NIlQGTYwhjyts4Gf8DUm30UQZwK9Yw5nA1NBCBcAefsR7dZfBaPAtYx1znLCMMlc1qstpC5PnM43p8xtmgpVgeZZ9JvU0tUmLnRS1zrzBNHicYnZZjkx9SbvmjU0qvLRSyxpTsBgiyR36BJjhkWDqkiVtmx8oqaWYxyJzlwDHKeu+9rx9kXoWMj7PRWaT8NCArDHN73yRGg2vVRQcfpvxUHSYitdKDqqUotPyUfTwlwO4ULNBKBcDXHjYcyxvq9nWANQbXInMHq7AvPgy+7yleeCm+MonFfOdyMwHcTUjLC8acTUq+GCbvhZ7XStzxFyULb61umc2kS3uW6hDDhnMhMVYh404MYGTRtLpcU571njcNjIlucRcirnF3wxmNUhLxA2V2YtooY4R7GMbL3EivN4sWmigjjNsYwOvBcduoY46rrGNLfwS3Y5NfXJ5n83S5zR1U5sPoQkfwAb2Hb8ooIk8gH1siEwOTRQBnGANH9Xd0G9GuK55zS6bQhXWw+KUX1ur8GP2NEefM6vwpLYYaSa1SZ2nKepFtFE4hnmQYsJKDYJjFkMkOWVsUrIYSuaUHHetzGpgyucO7To3+oYlShpRzKzI3CHAUcq6pzbJCtmNJJ8o6khkXhGeVmnTitdGkRWp8fBawZBjJ99zLOu1d3gAquxgfBQ9Rx8LLSZc1PUADHDtCSkz0J5llla0wzvt0ZI6wQVYEFydJOuaB74VqQcD9IqPgrhaEJZXjLiSeuBDLYhHhErdYSkwlTGSUqQDFhPZYtFCdbWoAsFpC9PnRJyYssbpIYiDUsIQCM4l/tkHrKWYqURvfxo12noxX2KbPbb5QuvCk6PCBbZ5zt+5wqlE/otGkfPc5DEP+YrTcUvwfxX+75i6afxVQwbAa0fUA0AlLPiuRFRGCcB7/KndC/0jw4eq9HW4aGklo3odZY0elzlsZVpsK1dfj8MhuFRT1fOQX1iceDlBXfJugsmlSqjqlIN+4IMlxnscNTbJas+q657G+EIWfBSZWhGy24HRt88LVDfqyQl+RUkNAqxR1h3teeUj9YKidkXmOeGh6fCjcTVrOI7UEVVGTWQmAA8Vh6m4Gag6nT+QqynIouipcm1T/AHh4wCUOxp9D384lt+pmauLfxNeXTtd4MzD9w4gXtsy4t7Uy/B65vgOsAaASJXFSDtGXD0QqA4LipE+IvVZDeKqaO1KO4kK6/OnfwkIEHxqZWbixFRJRcRpcCpPJJ1ksHe1bxZRLn2SMjRrFvwsp5W5Iz5MfFDU+6ao5fnAJVasTJMrYQ9yyedxm5M+Q2UFI/qoau9HGsNawgJB/APKHMBksLIKvAAAAABJRU5ErkJggg=="/>
                            </svg>
                        </div>
                        <span>Меню</span>
                    </div>
                    <transition name="fade">
                        <div class="menu" v-if="this.$store.state.desktopMenu">
                            <ul>
                                <li>
                                    <a href="https://idopobr.ru/" target="_blank">Дополнительное образование</a>
                                </li>
                            </ul>
                        </div>
                    </transition>
                    -->
                </div>

                <div class="new-header-search" v-if="this.$route.name === 'index'">

                    <div class="new-header-search-types">
                        <div class="new-header-search-type" @click="selectType('hotel')"
                             :class="[types.hotel ? 'active' : '']">
                            Отели
                        </div>
                        <div class="new-header-search-type" @click="selectType('avia')"
                             :class="[types.avia ? 'active' : '']">
                            Авиа
                        </div>
                    </div>

                    <form class="form-inputs" v-if="types.hotel">

                        <div class="form-group text">
                            <input
                                    type="text"
                                    placeholder="Город или отель"
                                    v-model="data.text"
                                    @keyup="triggerSearch">
                            <input-loader :loading="sLoading" :css-top="23" :css-right="15"/>
                            <auto-compete :hotels="autocomplete.hotels" :regions="autocomplete.regions"/>
                        </div>

                        <div class="form-group calendar">
                            <date-picker range v-model="data.date" placeholder="Даты бронирования"
                                         :format="'DD-MM-YYYY'"
                                         :value-type="'YYYY-MM-DD'" :disabled-date="$core.disabledDate"
                                         :clearable="false"/>
                        </div>

                        <div class="form-group placement">
                            <input type="text" class="search-room" v-model="guests" :readonly="true"
                                   @click="$store.state.selectPlacement = !$store.state.selectPlacement">
                            <div class="svg">
                                <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                        width="17px" height="9px">
                                    <defs>
                                        <filter id="Filter_0">
                                            <feFlood flood-color="rgb(47, 172, 187)" flood-opacity="1"
                                                     result="floodOut"/>
                                            <feComposite operator="atop" in="floodOut" in2="SourceGraphic"
                                                         result="compOut"/>
                                            <feBlend mode="normal" in="compOut" in2="SourceGraphic"/>
                                        </filter>

                                    </defs>
                                    <g filter="url(#Filter_0)">
                                        <path fill-rule="evenodd" fill="rgb(95, 95, 95)"
                                              d="M0.909,0.394 L16.561,0.383 L8.751,8.571 L0.909,0.394 Z"/>
                                    </g>
                                </svg>
                            </div>
                            <transition name="fade">
                                <div class="select-placement" v-if="this.$store.state.selectPlacement">
                                    <div class="placement-item">
                                        <div class="placement-item-titles">
                                            <div class="placement-item-title">Взрослые</div>
                                            <div class="placement-item-sub-title">старше 17 лет</div>
                                        </div>
                                        <div class="placement-item-counter">
                                            <button type="button" class="btn" @click="updateCounter('adults', 0)"
                                                    :disabled="data.adults < 2">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                     class="a2ka">
                                                    <path fill="currentColor"
                                                          d="M3 7a1 1 0 0 0 0 2h10a1 1 0 1 0 0-2H3Z"></path>
                                                </svg>
                                            </button>
                                            <input class="counter" v-model="data.adults" :disabled="true"/>
                                            <button type="button" class="btn" @click="updateCounter('adults', 1)">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                     class="a2ka">
                                                    <path fill="currentColor"
                                                          d="M9 3a1 1 0 0 0-2 0v4H3a1 1 0 0 0 0 2h4v4a1 1 0 1 0 2 0V9h4a1 1 0 1 0 0-2H9V3Z"></path>
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="placement-item">
                                        <div class="placement-item-titles">
                                            <div class="placement-item-title">Дети</div>
                                            <div class="placement-item-sub-title">от 0 до 17 лет</div>
                                        </div>
                                        <div class="placement-item-counter">
                                            <button type="button" class="btn" @click="updateCounter('children', 0)"
                                                    :disabled="data.children < 1">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                     class="a2ka">
                                                    <path fill="currentColor"
                                                          d="M3 7a1 1 0 0 0 0 2h10a1 1 0 1 0 0-2H3Z"></path>
                                                </svg>
                                            </button>
                                            <input class="counter" v-model="data.children" :disabled="true"/>
                                            <button type="button" class="btn" @click="updateCounter('children', 1)">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                     class="a2ka">
                                                    <path fill="currentColor"
                                                          d="M9 3a1 1 0 0 0-2 0v4H3a1 1 0 0 0 0 2h4v4a1 1 0 1 0 2 0V9h4a1 1 0 1 0 0-2H9V3Z"></path>
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="placement-item child-age" v-if="data.children > 0">
                                        <multiselect
                                                v-for="index in data.children"
                                                :key="index"
                                                v-model="childrenAges[index - 1]"
                                                track-by="name"
                                                label="name"
                                                :options="options"
                                                :searchable="false"
                                                :close-on-select="true"
                                                :show-labels="false"
                                                placeholder="Укажите возраст"
                                                :openDirection="'below'"
                                        ></multiselect>
                                    </div>
                                </div>
                            </transition>
                        </div>

                    </form>

                    <header-avia-search ref="avia" v-else-if="types.avia"/>

                    <button type="submit" class="btn" @click="startSearch">Найти</button>
                </div>
            </div>
        </div>
    </header>
</template>

<script>

import AutoCompete from "@/components/AutoCompete/hotel/AutoCompete.vue";
import InputLoader from "@/components/loaders/input/loader.vue";
import HeaderAviaSearch from "@/components/UI/header/search/avia.vue";

export default {
    name: 'NewHeader',
    components: {HeaderAviaSearch, InputLoader, AutoCompete},
    data: function () {
        return {
            data: {
                text: '',
                type: 0,
                id: 0,
                date: [
                    this.$moment().add(7, 'days').format('Y-MM-DD'),
                    this.$moment().add(9, 'days').format('Y-MM-DD')
                ],
                adults: 1,
                children: 0,
                ages: [],
            },
            autocomplete: {
                hotels: [],
                regions: [],
            },
            types: {
                hotel: true,
                avia: false,
            },
            childrenAges: [],
            options: [
                {name: 'До года', code: '0'},
                {name: '1 год', code: '1'},
                {name: '2 года', code: '2'},
                {name: '3 года', code: '3'},
                {name: '4 года', code: '4'},
                {name: '5 лет', code: '5'},
                {name: '6 лет', code: '6'},
                {name: '7 лет', code: '7'},
                {name: '8 лет', code: '8'},
                {name: '9 лет', code: '9'},
                {name: '10 лет', code: '10'},
                {name: '11 лет', code: '11'},
                {name: '12 лет', code: '12'},
                {name: '13 лет', code: '13'},
                {name: '14 лет', code: '14'},
                {name: '15 лет', code: '15'},
                {name: '16 лет', code: '16'},
                {name: '17 лет', code: '17'}
            ],
            sLoading: false,
            timer: null,
        }
    },
    watch: {
        childrenAges(newValue) {
            this.data.ages = []
            Object.keys(newValue).forEach((index) => {
                this.data.ages.push(this.childrenAges[index].code)
            })
        }
    },
    computed: {
        guests: function () {
            let count = this.data.adults + this.data.children;
            return count + ' ' + this.$core.declination(count, ['гость', 'гостя', 'гостей']);
        },
    },
    async mounted() {
        this.$root.$on('autocomplete', (val) => {
            this.setAutoComplete(val)
        });
    },
    methods: {
        openFavourites: function () {
            if (!this.$core.checkAuth()) {
                this.$root.$emit('openModal', 'login');
            } else {
                this.$router.push({
                    name: 'favourites',
                });
            }
        },
        startSearch: function () {
            if (this.types.hotel) {
                if (this.data.id <= 0) {
                    this.$toast.error('Выберите город или отель')
                    return false
                }

                if (this.data.type === 1) {
                    this.$router.push({
                        name: 'hotels',
                        query: {
                            id: this.data.id,
                            text: this.data.text,
                            type: this.data.type,
                            dateArrival: this.data.date[0],
                            dateDeparture: this.data.date[1],
                            adults: this.data.adults,
                            children: this.data.ages,
                        }
                    })
                } else {
                    this.$router.push({
                        name: 'hotel',
                        params: {id: this.data.id},
                        query: {
                            id: this.data.id,
                            text: this.data.text,
                            type: this.data.type,
                            dateArrival: this.data.date[0],
                            dateDeparture: this.data.date[1],
                            adults: this.data.adults,
                            children: this.data.ages
                        }
                    })
                }

            } else if (this.types.avia) {
                this.$refs.avia.startSearch()
            }
        },
        selectType: function (val) {
            let $this = this;
            this.types[val] = true;
            Object.keys(this.types).forEach(function (key) {
                if (key !== val) {
                    $this.types[key] = false;
                }
            });
            this.data.text = ''
            this.data.type = 0
            this.data.id = 0
            this.data.date[0] = this.$moment().add(7, 'days').format('Y-MM-DD')
            this.data.date[1] = this.$moment().add(9, 'days').format('Y-MM-DD')
            this.data.adults = 1
            this.data.children = 0
            this.data.ages = []
        },
        openHotels: function () {
            this.$router.push({
                name: 'hotels',
                query: {
                    id: 2395,
                    text: 'Москва',
                    type: 1,
                    dateArrival: this.data.date[0],
                    dateDeparture: this.data.date[1],
                    adults: this.data.adults,
                    children: this.data.ages,
                }
            })
        },
        triggerMenu: function () {
            this.$root.$emit('triggerMenu');
        },
        updateCounter: function (variable, type) {
            if (type === 0 && variable === 'adults' && this.data[variable] > 1) {
                this.data[variable]--;
            } else if (type === 0 && variable === 'children' && this.data[variable] >= 1) {
                this.data[variable]--;
                this.childrenAges.pop();
            } else if (variable === 'children' && this.data[variable] < 4) {
                this.data[variable]++;
            } else if (variable !== 'children' && this.data[variable] < 6) {
                this.data[variable]++;
            }
        },
        setAutoComplete: function (val) {
            this.data.id = val.id
            this.data.text = val.name
            this.data.type = val.type
        },
        // помощь в поиске отелей/регионов
        triggerSearch: function () {
            if (!this.timer) {
                if (this.data.text.length > 1) {
                    this.sLoading = true
                    this.timer = setTimeout(() => {
                        this.$store.dispatch('getAutoCompleteRegionAndHotel', {
                            query: this.data.text
                        }).then((res) => {
                            this.autocomplete.hotels = res.data.data.hotels
                            this.autocomplete.regions = res.data.data.regions
                            this.$store.state.searchTrigger = true
                            this.timer = null
                            this.sLoading = false
                        }).catch(() => {
                            this.$toast.error('Ошибка... Повторите поиск')
                            this.timer = null
                            this.sLoading = false
                            this.$store.state.searchTrigger = false
                        })
                    }, 500)
                }
            }
        },
        triggerProfile: function () {
            if (!this.$core.checkAuth()) {
                this.$root.$emit('openModal', 'login');
            } else {
                if (this.$store.state.device === 'mob') {
                    this.$root.$emit('triggerProfile');
                } else {
                    this.$router.push({
                        name: 'profile',
                    });
                }
            }
        },
    }
}
</script>
