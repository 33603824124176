import Vue from 'vue';
import Vuex from 'vuex';
import login from './modules/auth/login';
import user from './modules/user/user';
import index from './modules/index/index';
import catalog from './modules/catalog/catalog';
import hotel from './modules/hotel/hotel';
import confirmation from './modules/confirmation/confirmation';
import tours from './modules/tours/tours'
import connectHotel from "./modules/static/connect-hotel";
import services from './modules/services/services';
import basket from './modules/basket/basket'
import search from './modules/search/hotel/search'
import map from './modules/map/map'
import promocode from './modules/promocode/promocode';
import aviaSearch from './modules/search/avia/search'
Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        token: localStorage.getItem('token') || '',
        modal: {
            login: false,
            createPassenger: false,
            successBooking: false,
            errorBooking: false,
            review: false,
            cancelOrder: false,
            successCancelOrder: false,
            offer: false,
            aboutNumber: false,
            serviceBooking: false,
            viewMap: false,
        },
        backdrop: false,
        mobileMenu: false,
        desktopMenu: false,
        profileMenu: false,
        selectPlacement: false,
        searchTrigger: false,
        searchTriggerFrom: false,
        autoCompleteMobile: false,
        avia: {
            autoCompleteMobile: false,
            searchMobile: false,
        },
        childrenAgesObject: [],
        catalogMobileSearch: false,
        scroll: 0,
        width: 0,
        height: 0,
        device: 'web',
        baseURL: 'https://api.alamat.ru/',
    },
    actions: {
        renderModal(ctx, name) {
            ctx.commit('updateModalVisible', name);
        },
        triggerDesktop(ctx) {
            ctx.commit('closeDesktop');
        },
        triggerMobile(ctx) {
            ctx.commit('closeMobile');
        },
        closeModals(ctx) {
            ctx.commit('closeModals');
        },
        closeWindows(ctx) {
            ctx.commit('closeWindows');
        },
        logout(ctx) {
            ctx.commit('auth_logout');
        }
    },
    mutations: {
        updateModalVisible(state, name) {
            this.state.backdrop = !this.state.backdrop;
            this.state.modal[name] = !this.state.modal[name];
        },
        closeDesktop(state) {
            state.desktopMenu = false;
        },
        closeMobile(state) {
            state.mobileMenu = false;
            state.profileMenu = false;
        },
        closeModals(state) {
            state.modal.login = false;
            state.modal.createPassenger = false;
            state.modal.successBooking = false;
            state.modal.errorBooking = false;
            state.modal.review = false;
            state.modal.cancelOrder = false;
            state.modal.successCancelOrder = false;
            state.modal.offer = false;
            state.modal.aboutNumber = false;
            state.modal.serviceBooking = false;
            state.modal.viewMap = false;
        },
        closeWindows(state) {
            state.mobileMenu = false;
            state.profileMenu = false;
        },
        auth_success(state, token) {
            state.token = token;
        },
        auth_logout(state) {
            state.token = '';
            localStorage.removeItem('token');
        }
    },
    getters: {
        isLoggedIn: state => !!state.token,
        width: state => state.width,
        height: state => state.height,
        scroll: state => state.scroll,
        previousRoute: (state) => {
            const historyLen = state.routerHistory.length;
            if (historyLen === 0) return null;
            return state.routerHistory[historyLen - 1];
        },
    },
    modules: {login, user, index, catalog, hotel, confirmation, tours, connectHotel, services, basket, search, map, promocode, aviaSearch}

});
