import axios from 'axios'
import Vue from "vue";

export default {
    state: {
        errorCode: ''
    },
    getters: {
        getErrorCode: state => state.errorCode,
    },
    actions: {
        login(ctx, data) {
            return new Promise((resolve, reject) => {
                axios.post('/auth/login', data).then(response => {
                    resolve(response);
                }).catch(error => {
                    Vue.$toast.error(error.response.data.message)
                    reject(error);
                });
            });
        },
        checkCode(ctx, data) {
            return new Promise((resolve, reject) => {
                axios.post('/auth/code', {
                    token: data.token,
                    code: data.code
                }).then(response => {
                    if(response.data.result === 'ok'){
                        const token = response.data.data.token
                        localStorage.setItem('token', token)
                        ctx.commit('auth_success', response.data.data.token)
                        ctx.commit('updateModalVisible', 'login');
                    }
                    resolve(response);
                }).catch(error => {
                    ctx.commit('setErrorCode', error.response.data.message)
                    reject(error);
                });
            });
        },
    },
    mutations: {
        setErrorCode: function (state, data) {
            state.errorCode = data
        }
    }
}
