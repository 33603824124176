<template>
  <div class="mobile-wrapper">
    <div class="mobile-header">
      <div class="mobile-header-close" @click="close">
        <svg width="11" height="18" viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd"
                d="M2.43513 8.91619L10.1348 1.21656L8.9182 2.17716e-07L-0.000948719 8.91913L1.21562 10.1357L1.21856 10.1327L8.91714 17.8314L10.1337 16.6148L2.43513 8.91619Z"
                fill="black"/>
        </svg>
      </div>
      <div class="mobile-header-title">Заказы</div>
    </div>
    <div class="mobile-body">
      <div class="profile-orders" v-if="data.orders.length > 0">
        <div class="profile-order" v-for="(order, index) in data.orders" :key="index">
          <div class="profile-order-header">
            <div class="order-number">
              <span class="number">
                <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="5" cy="5" r="5" fill="#2DCC70"/>
                </svg>
                Заказ {{ order.id }}
              </span>
              <span class="status">Оформлен</span>
            </div>
            <div class="order-amount">
              <span>
                <b>{{ parseFloat(order.amount).toLocaleString('RU-ru') }} ₽</b>
              </span>
              <div class="open-attachment">
                <svg width="11" height="7" viewBox="0 0 11 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M10.669 0.256714C11.0795 0.626173 11.1127 1.25846 10.7433 1.66897L6.24329 6.66897C6.05364 6.87969 5.78348 7.00001 5.49999 7.00001C5.21651 7.00001 4.94634 6.87969 4.7567 6.66897L0.256698 1.66897C-0.112761 1.25846 -0.079483 0.626173 0.331027 0.256714C0.741537 -0.112745 1.37383 -0.0794662 1.74329 0.331044L5.49999 4.50516L9.2567 0.331044C9.62616 -0.0794662 10.2584 -0.112745 10.669 0.256714Z"
                        fill="white"/>
                </svg>
              </div>
            </div>
          </div>
          <div class="profile-order-body" v-if="order.rooms.length > 0">
            <div class="block">
              <span>
                <b>Отель: </b>
                {{ order.rooms[0].hotel.name }}
              </span>
            </div>
            <div class="block">
              <span>
                <b>Дата: </b>
                {{ $moment(order.rooms[0].arrival_date).format('D MMM') + ' -> ' + $moment(order.rooms[0].departure_date).format('D MMM') }}
              </span>
              <span>
                <b>Гости: </b>
                {{ order.rooms[0].guests.length }}
              </span>
            </div>
          </div>
          <div class="profile-order-attachments">
            <a class="order-attachment" v-if="order.voucher" :href="order.voucher" target="_blank">
              <div class="svg">
                <svg width="28" height="35" viewBox="0 0 28 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M7.46692 22.1914C7.14492 22.1914 6.92792 22.2229 6.81592 22.2544V24.3159C6.94892 24.3474 7.11517 24.3562 7.34442 24.3562C8.18267 24.3562 8.69892 23.9327 8.69892 23.2169C8.69892 22.5764 8.25442 22.1914 7.46692 22.1914ZM13.5692 22.2124C13.2192 22.2124 12.9917 22.2439 12.8569 22.2754V26.8429C12.9917 26.8744 13.2087 26.8744 13.4047 26.8744C14.8344 26.8849 15.7654 26.0974 15.7654 24.4314C15.7759 22.9789 14.9272 22.2124 13.5692 22.2124Z"
                      fill="#FF0000"/>
                  <path
                      d="M17.5 0H3.5C2.57174 0 1.6815 0.368749 1.02513 1.02513C0.368749 1.6815 0 2.57174 0 3.5V31.5C0 32.4283 0.368749 33.3185 1.02513 33.9749C1.6815 34.6312 2.57174 35 3.5 35H24.5C25.4283 35 26.3185 34.6312 26.9749 33.9749C27.6313 33.3185 28 32.4283 28 31.5V10.5L17.5 0ZM9.6215 24.8325C9.08075 25.34 8.28275 25.5675 7.3535 25.5675C7.17332 25.5695 6.99322 25.5589 6.8145 25.536V28.0315H5.25V21.1435C5.95605 21.0382 6.66946 20.9902 7.38325 21C8.358 21 9.051 21.1855 9.51825 21.5583C9.96275 21.9118 10.2638 22.491 10.2638 23.1735C10.262 23.8595 10.0345 24.4388 9.6215 24.8325ZM16.2838 27.2038C15.5488 27.8145 14.4305 28.105 13.0638 28.105C12.2448 28.105 11.6655 28.0525 11.2717 28V21.1453C11.9781 21.0422 12.6912 20.9936 13.405 21C14.7297 21 15.5908 21.238 16.2628 21.7455C16.989 22.2845 17.444 23.1438 17.444 24.3775C17.444 25.7128 16.9558 26.635 16.2838 27.2038ZM22.75 22.3475H20.069V23.9417H22.575V25.2263H20.069V28.0332H18.4835V21.0525H22.75V22.3475ZM17.5 12.25H15.75V3.5L24.5 12.25H17.5Z"
                      fill="#FF0000"/>
                </svg>
              </div>
              <b>Ваучер на отель</b>
            </a>
          </div>
        </div>
        <div class="profile-order-more" v-if="data.totalCountOrder > 3 && filters.limit < data.totalCountOrder"
             @click="showMoreOrders">
          <b>Показать ещё</b>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapGetters} from "vuex";

export default {
  name: 'my-orders',
  data: function () {
    return {
      data: {
        totalCountOrder: 0,
        orders: []
      },
      filters: {
        limit: 3,
        offset: 0
      },
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
    bookingRange: function () {
      let $this = this;
      return this.data.orders.map(function (item) {
        return $this.$moment(item.orderRooms[0].arrival_datetime).format('D MMM') + ' -> ' + $this.$moment(item.orderRooms[0].departure_datetime).format('D MMM');
      });
    },
    countPassenger: function () {
      return this.data.orders.map(function (item) {
        return item.orderRooms[0].adult_count;
      });
    },
  },
  async mounted() {
    this.getUserOrders();
  },
  methods: {
    showMoreOrders: function () {
      this.filters.limit += 3;
      this.getUserOrders();
    },
    getUserOrders: function () {
      this.$store.dispatch('getUserOrders', this.filters).then(() => {
        this.data.orders = this.userInfo.orders;
        this.data.totalCountOrder = this.userInfo.totalCountOrder;
      });
    },
    close: function () {
      this.$root.$emit('triggerProfileTab', 'myOrders');
    },
  }
}
</script>
