<template>
  <div class="preloader">
    <img src="@/assets/images/loaders/preloader.png" :style="style">
  </div>
</template>

<script>
export default {
  name: 'preloader',
  data: function () {
    return {
      isShow: true,
      turn: 0.1,
      style: '',
    }
  },
  mounted() {
    this.rotation();
  },
  methods: {
    rotation: function () {
      setTimeout(() => {
        this.turn += 0.1;
        this.style = "transform: rotate(" + this.turn + "turn)";
        this.rotation();
      }, 100);
    },
  }
}
</script>

<style scoped>
.preloader {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background: #FFFFFF;
  z-index: 9998;
}

.preloader img {
  overflow: auto;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 15%;
  height: auto;
}

@media screen and (max-width: 768px) {
  .preloader img {
    width: 30%;
  }
}

@media screen and (max-width: 768px) {
  .preloader img {
    width: 30%;
  }
}

</style>
