<template>
  <div class="modal-backdrop"></div>
</template>

<script>
export default {
  name: 'backdrop',
  data: function () {
    return {}
  },
  async mounted() {
  },
  methods: {}
}
</script>
