export default {
    state: {
        dataService: [],
        dataAvia: [],
        dataHotel: []
    },
    getters: {
    },
    actions: {
        setService(ctx, data) {
            ctx.commit('setServiceInfo', data)
        },
        setHotel(ctx, data) {
            ctx.commit('setHotelInfo', data)
        },
        setAvia(ctx, data) {
            ctx.commit('setAviaInfo', data)
        }
    },
    mutations: {
        setServiceInfo(state, data) {
            localStorage.setItem('dataService', JSON.stringify(data))
        },
        setHotelInfo(state, data) {
            localStorage.setItem('dataHotel', JSON.stringify(data))
        },
        setAviaInfo(state, data) {
            localStorage.setItem('dataAvia', JSON.stringify(data))
        }
    }
}
