<template>
  <div class="service-booking-modal modal">
    <div class="modal-dialog modal-avg">
      <div class="modal-content">
        <div class="modal-header-v2">
          <h1 class="title">
            Выберите количество участников
          </h1>
          <div class="close pointer" @click="close">
            <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g filter="url(#filter0_b_631_2916)">
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M13 26C20.1797 26 26 20.1797 26 13C26 5.8203 20.1797 0 13 0C5.8203 0 0 5.8203 0 13C0 20.1797 5.8203 26 13 26Z"
                      fill="#F5F5F5"/>
              </g>
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M17.8754 8.34029C17.466 7.93081 16.8065 7.9264 16.4024 8.33045L12.9336 11.7993L9.54098 8.40673C9.1315 7.99725 8.46754 7.99731 8.05799 8.40686C7.64844 8.81641 7.64838 9.48036 8.05787 9.88984L11.4504 13.2824L8.1146 16.6183C7.71056 17.0223 7.71496 17.6818 8.12445 18.0913C8.53393 18.5008 9.19342 18.5052 9.59747 18.1011L12.9333 14.7653L16.326 18.158C16.7355 18.5675 17.3994 18.5674 17.809 18.1579C18.2185 17.7483 18.2186 17.0844 17.8091 16.6749L14.4164 13.2822L17.8853 9.81331C18.2893 9.40927 18.2849 8.74978 17.8754 8.34029Z"
                    fill="black"/>
              <defs>
                <filter id="filter0_b_631_2916" x="-45.7896" y="-45.7896" width="117.579" height="117.579"
                        filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                  <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                  <feGaussianBlur in="BackgroundImage" stdDeviation="22.8948"/>
                  <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_631_2916"/>
                  <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_631_2916" result="shape"/>
                </filter>
              </defs>
            </svg>
          </div>
        </div>
        <div class="modal-body-df">
          <div class="d-flex justify-content-between">
            <div class="placement-group">
              <label class="label">Кол-во участников</label>
              <input type="text" class="search-room" v-model="selectedClient" :readonly="true"
                     @click="$store.state.selectPlacement = !$store.state.selectPlacement">
              <transition name="fade">
                <div class="select-placement" v-if="this.$store.state.selectPlacement">
                  <div class="placement-item">
                    <div class="placement-item-titles">
                      <div class="placement-item-title">Взрослые</div>
                      <div class="placement-item-sub-title">старше 17 лет</div>
                    </div>
                    <div class="placement-item-counter">
                      <button class="btn" @click="updateCounter('adults', 0)" :disabled="data.adults < 2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" class="a2ka">
                          <path fill="currentColor" d="M3 7a1 1 0 0 0 0 2h10a1 1 0 1 0 0-2H3Z"></path>
                        </svg>
                      </button>
                      <input class="counter" v-model="data.adults" :disabled="true"/>
                      <button class="btn" @click="updateCounter('adults', 1)">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" class="a2ka">
                          <path fill="currentColor"
                                d="M9 3a1 1 0 0 0-2 0v4H3a1 1 0 0 0 0 2h4v4a1 1 0 1 0 2 0V9h4a1 1 0 1 0 0-2H9V3Z"></path>
                        </svg>
                      </button>
                    </div>
                  </div>
                  <div class="placement-item">
                    <div class="placement-item-titles">
                      <div class="placement-item-title">Дети</div>
                      <div class="placement-item-sub-title">от 0 до 17 лет</div>
                    </div>
                    <div class="placement-item-counter">
                      <button class="btn" @click="updateCounter('children', 0)" :disabled="data.children < 1">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" class="a2ka">
                          <path fill="currentColor" d="M3 7a1 1 0 0 0 0 2h10a1 1 0 1 0 0-2H3Z"></path>
                        </svg>
                      </button>
                      <input class="counter" v-model="data.children" :disabled="true"/>
                      <button class="btn" @click="updateCounter('children', 1)">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" class="a2ka">
                          <path fill="currentColor"
                                d="M9 3a1 1 0 0 0-2 0v4H3a1 1 0 0 0 0 2h4v4a1 1 0 1 0 2 0V9h4a1 1 0 1 0 0-2H9V3Z"></path>
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              </transition>
            </div>
            <div class="btn-agree" v-if="this.$store.getters.width >= 600">
              <a class="btn btn-info" @click="set">Подтвердить</a>
            </div>
          </div>
          <template v-if="Object.keys(service).length > 0 && Object.keys(service.slots).length > 0">
            <div class="title">Выберите дату</div>
            <div class="dates">
              <div class="item" :class="[index === selectDate ? 'active' : '']"
                   v-for="(date, index) in service.dates" :key="index"
                   @click="selectDate = index">
                {{ $moment(date).format('dddd, D MMMM') }}
              </div>
            </div>
            <div class="title">Выберите время</div>
            <div class="slots">
              <div class="item" :class="[index === selectInterval ? 'active' : '']"
                   v-for="(item, index) in service.slots[selectDate].slots"
                   :key="index" @click="selectInterval = index">
                {{ item.slot }}
                <span>{{ item.price }}₽</span>
              </div>
            </div>
          </template>
          <div class="d-flex justify-content-end mt-3" v-if="this.$store.getters.width <= 600">
            <div class="btn-agree">
              <a class="btn btn-info" @click="set">Подтвердить</a>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapGetters} from "vuex";

export default {
  name: 'service-booking-modal',
  data: function () {
    return {
      service: [],
      data: {
        adults: 1,
        children: 0
      },
      selectDate: 0,
      selectInterval: 0
    }
  },
  computed: {
    ...mapGetters(['_getService']),
    selectedClient: function () {
      return 'Взрослых: ' + this.data.adults + ' Детей: ' + this.data.children
    },
  },
  mounted() {
    this.service = this._getService;
  },
  methods: {
    updateCounter: function (variable, type) {
      if (type === 0 && variable === 'adults' && this.data[variable] > 1) {
        this.data[variable]--;
      } else if (type === 0 && variable === 'children' && this.data[variable] >= 1) {
        this.data[variable]--;
      } else {
        this.data[variable]++;
      }
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.getServicesInfo();
      }, 500);
    },
    getServicesInfo: async function () {
      let payload = {
        id: this.service.id,
        adults: this.data.adults,
        children: this.data.children,
        dateFrom: this.$route.query.dateFrom ? this.$route.query.dateFrom : '',
        dateTo: this.$route.query.dateTo ? this.$route.query.dateTo : '',
      }
      await this.$store.dispatch('getService', payload).then((res) => {
        this.service = res.data.data.service;

        if (Object.keys(this.service.slots).length === 0) {
          //this.$store.state.selectPlacement = false;
          this.$notify({type: 'error', text: 'На выбранное количество участников нет свободных дат'});
        }
      });
    },
    set: function () {
      this.$store.dispatch('setService',
          {'service': this.service, 'peoples': this.data,
            'date': this.selectDate, 'interval': this.selectInterval,
            'countPeople': parseInt(this.data.adults) + parseInt(this.data.children)}).then(() => {
        this.$root.$emit('setCountBasket');
        this.$notify('Выбранная услуга сохранена')
        this.close()
      })

    },
    close: function () {
      this.$root.$emit('openModal', 'serviceBooking');
    },
  }
}
</script>

<style>
.placement-group {
  position: relative;
  width: 100%;
  min-width: 255px;
  max-width: 350px;
}

.placement-group label {
  position: absolute;
  left: 10px;
  top: 4px;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #96A3AE;
}

.placement-group input {
  height: 45px;
  font-weight: bold;
  font-family: 'Roboto', serif;
  cursor: pointer;
}

.placement-group input.search-room {
  padding-top: 10px;
}

.placement-group:after {
  content: url("data:image/svg+xml,%3Csvg width='18' height='20' viewBox='0 0 18 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9 0C8.21207 -1.17411e-08 7.43185 0.155195 6.7039 0.456723C5.97595 0.758251 5.31451 1.20021 4.75736 1.75736C4.20021 2.31451 3.75825 2.97595 3.45672 3.7039C3.15519 4.43185 3 5.21207 3 6C3 6.78793 3.15519 7.56815 3.45672 8.2961C3.75825 9.02405 4.20021 9.68549 4.75736 10.2426C5.31451 10.7998 5.97595 11.2417 6.7039 11.5433C7.43185 11.8448 8.21207 12 9 12C10.5913 12 12.1174 11.3679 13.2426 10.2426C14.3679 9.11742 15 7.5913 15 6C15 4.4087 14.3679 2.88258 13.2426 1.75736C12.1174 0.632141 10.5913 2.37122e-08 9 0V0ZM9 2C10.0609 2 11.0783 2.42143 11.8284 3.17157C12.5786 3.92172 13 4.93913 13 6C13 7.06087 12.5786 8.07828 11.8284 8.82843C11.0783 9.57857 10.0609 10 9 10C7.93913 10 6.92172 9.57857 6.17157 8.82843C5.42143 8.07828 5 7.06087 5 6C5 4.93913 5.42143 3.92172 6.17157 3.17157C6.92172 2.42143 7.93913 2 9 2V2ZM6.316 13.051L6.162 13H6L5.775 13.004C4.22371 13.0622 2.75536 13.7194 1.67847 14.8375C0.601566 15.9556 -5.69771e-05 17.4476 4.04725e-09 19C4.04725e-09 19.2652 0.105357 19.5196 0.292893 19.7071C0.48043 19.8946 0.734784 20 1 20C1.26522 20 1.51957 19.8946 1.70711 19.7071C1.89464 19.5196 2 19.2652 2 19L2.005 18.8C2.05458 17.8095 2.47033 16.8728 3.17157 16.1716C3.87281 15.4703 4.80954 15.0546 5.8 15.005L5.858 15.003L6.044 15.062C7.974 15.629 10.026 15.629 11.956 15.062L12.141 15.003L12.2 15.005C13.225 15.0563 14.191 15.4996 14.8983 16.2432C15.6056 16.9868 16 17.9738 16 19C16 19.2652 16.1054 19.5196 16.2929 19.7071C16.4804 19.8946 16.7348 20 17 20C17.2652 20 17.5196 19.8946 17.7071 19.7071C17.8946 19.5196 18 19.2652 18 19C18 17.4087 17.3679 15.8826 16.2426 14.7574C15.1174 13.6321 13.5913 13 12 13H11.838L11.684 13.051L11.355 13.153C9.70375 13.63 7.94659 13.5945 6.316 13.051V13.051Z' fill='%2396A3AE'/%3E%3C/svg%3E%0A");
  cursor: pointer;
  position: absolute;
  right: 12px;
  bottom: 8px;
}

.btn-agree .btn {
  width: 150px;
  height: 45px;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000000;
}

.service-booking-modal .modal-body-df .title {
  font-weight: 900;
  font-size: 20px;
  line-height: 25px;
  color: #383838;
  margin-top: 15px;
}

.service-booking-modal .dates {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  height: 65px;
  margin-left: -5px;
  overflow-x: auto;
}

.service-booking-modal .dates::-webkit-scrollbar {
  height: 5px;
}

.service-booking-modal .dates .item {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 185px;
  height: 45px;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #383838;
  background: #F7F7F7;
  border-radius: 12px;
  margin-top: 10px;
  margin-left: 5px;
  cursor: pointer;
}

.service-booking-modal .dates .item.active {
  background: #43B02A;
  color: #ffffff;
  font-weight: 500;
}

.service-booking-modal .slots {
  display: flex;
  justify-content: flex-start;
  margin-left: -5px;
  flex-flow: row wrap;
  width: 100%;
}

@media screen and (max-width: 700px) {
  .service-booking-modal .slots {
    flex-flow: nowrap;
    height: 65px;
    overflow-x: auto;
  }
}

.service-booking-modal .slots .item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 160px;
  height: 45px;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #383838;
  background: #F7F7F7;
  border-radius: 12px;
  margin-top: 10px;
  margin-left: 5px;
  cursor: pointer;
}


.service-booking-modal .slots .item.active {
  background: #43B02A;
  color: #FFFFFF;
  font-weight: 500;
}

.service-booking-modal .slots .item.active span {
  color: #FFFFFF;
  font-weight: 500;
}

.service-booking-modal .slots .item span {
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #383838;

}

</style>
