import Vue from 'vue'
import VueYandexMetrika from 'vue-yandex-metrika'

Vue.use(VueYandexMetrika, {
    id: 93432119,
    env: 'production',
    options: {
        webvisor: true
    }
})
