<template>
    <div id="app" @click="handleClick">
        <preloader v-if="show"/>
        <notifications position="top right"/>

        <template v-show="!show">
            <NewHeader id="header"/>
            <router-view/>
            <NewFooter/>
        </template>

        <!-- MOBILE MENU -->

        <transition enter-active-class="animate__animated animate__slideInRight"
                    leave-active-class="animate__animated animate__slideOutRight">
            <mobile-menu v-if="this.$store.state.mobileMenu"/>
        </transition>

        <!-- MOBILE MENU END -->

        <!-- MOBILE PROFILE -->

        <transition enter-active-class="animate__animated animate__slideInLeft"
                    leave-active-class="animate__animated animate__slideOutLeft">
            <profile-menu v-if="this.$store.state.profileMenu"/>
        </transition>

        <template v-if="this.$store.state.width <= 992">
            <transition name="fade">
                <my-data v-if="this.profilePage.myData"></my-data>
            </transition>

            <transition name="fade">
                <my-bonuses v-if="this.profilePage.myBonuses"></my-bonuses>
            </transition>

            <transition name="fade">
                <my-orders v-if="this.profilePage.myOrders"></my-orders>
            </transition>

            <transition name="fade">
                <my-cards v-if="this.profilePage.myCards"></my-cards>
            </transition>

            <transition name="fade">
                <my-book v-if="this.profilePage.myBook"></my-book>
            </transition>
        </template>

        <!-- MOBILE PROFILE END -->

        <!-- TOGGLE TOP -->

        <toggle-top v-if="this.$store.state.scroll >= 100 &&
                    $route.name !== 'hotels' &&
                    $route.name !== 'tours' &&
                    $route.name !== 'avia-catalog'"
                    v-on:click.native="scrollTop()"/>

        <!-- TOGGLE TOP END -->

        <!-- MODAL WINDOWS -->

        <transition name="fade">
            <login-modal v-if="this.$store.state.modal.login"/>
        </transition>

        <transition name="fade">
            <create-passenger-modal v-if="this.$store.state.modal.createPassenger"/>
        </transition>

        <transition name="fade">
            <success-booking-modal v-if="this.$store.state.modal.successBooking"/>
        </transition>

        <transition name="fade">
            <error-booking-modal v-if="this.$store.state.modal.errorBooking"/>
        </transition>

        <transition name="fade">
            <review-modal v-if="this.$store.state.modal.review"/>
        </transition>

        <transition name="fade">
            <cancel-order-modal v-if="this.$store.state.modal.cancelOrder"/>
        </transition>

        <transition name="fade">
            <success-cancel-order-modal v-if="this.$store.state.modal.successCancelOrder"/>
        </transition>

        <transition name="fade">
            <offer-modal v-if="this.$store.state.modal.offer"/>
        </transition>

        <transition name="fade">
            <about-number-modal v-if="this.$store.state.modal.aboutNumber"/>
        </transition>

        <transition name="fade">
            <service-booking-modal v-if="this.$store.state.modal.serviceBooking"/>
        </transition>

        <!-- MODAL WINDOWS END -->
        <transition name="fade">
            <backdrop v-if="this.$store.state.backdrop"/>
        </transition>
    </div>
</template>

<script>
//import Header from '../src/components/header';
import Preloader from "./components/loaders/preloader";
import ToggleTop from "./components/toggle-top";
import MobileMenu from "./components/mobile/menu";
import ProfileMenu from "./components/mobile/profile";
import LoginModal from "./components/modal/login";
import MyData from "./components/mobile/profile/my-data";
import MyBonuses from "./components/mobile/profile/my-bonuses";
import MyOrders from "./components/mobile/profile/my-orders";
import MyCards from "./components/mobile/profile/my-cards";
import MyBook from "./components/mobile/profile/my-book";
import CreatePassengerModal from "./components/modal/profile/create-passenger";
import SuccessBookingModal from "@/components/modal/success-booking-modal";
import ErrorBookingModal from "@/components/modal/error-booking-modal";
import ReviewModal from "./components/modal/hotel/review-modal";
import CancelOrderModal from "@/components/modal/profile/cancel-order-modal";
import SuccessCancelOrderModal from "@/components/modal/profile/success-cancel-order-modal";
import NewHeader from "@/components/NewHeader";
import Backdrop from "@/components/backdrop/backdrop";
import NewFooter from "@/components/NewFooter";
import OfferModal from "@/components/modal/static-page/connect-hotel/offer-modal";
import AboutNumberModal from "@/components/modal/hotel/about-number-modal";
import ServiceBookingModal from "@/components/modal/services/service-booking-modal";

export default {
    name: 'App',
    data: function () {
        return {
            show: true,
            profilePage: {
                myData: false,
                myBonuses: false,
                myOrders: false,
                myCards: false,
                myBook: false
            }
        }
    },
    components: {
        ServiceBookingModal,
        AboutNumberModal,
        OfferModal,
        NewFooter,
        Backdrop,
        NewHeader,
        SuccessCancelOrderModal,
        CancelOrderModal,
        ReviewModal,
        ErrorBookingModal,
        SuccessBookingModal,
        CreatePassengerModal,
        MyBook, MyCards, MyOrders, MyBonuses, MyData, LoginModal, ProfileMenu, MobileMenu, ToggleTop, Preloader
    },
    async mounted() {
        let html = document.documentElement;
        this.scroll = html.scrollTop;
        this.$store.state.width = html.clientWidth;
        this.verifyDevice();

        window.addEventListener('resize', () => {
            this.$store.state.width = document.documentElement.clientWidth;
            this.verifyDevice();
        });

        this.showToggle();

        window.addEventListener('scroll', () => {
            this.$store.state.scroll = html.scrollTop;
        });

        this.$root.$on('triggerMenu', () => {
            this.triggerMenu();
        });

        this.$root.$on('triggerProfile', () => {
            this.triggerProfile();
        });

        this.$root.$on('triggerProfileTab', (val) => {
            this.profilePage[val] = !this.profilePage[val];
        });

        this.$root.$on('triggerCatalogSearchMobile', () => {
            this.triggerCatalogSearchMobile();
        });

        this.$root.$on('triggerAviaSearchMobile', () => {
            this.triggerAviaSearchMobile();
        });

        this.$root.$on('logout', () => {
            this.logout();
        });

        this.$root.$on('openModal', (val) => {
            this.$store.dispatch('renderModal', val);
        });
    },
    computed: {},
    methods: {
        showToggle: function () {
            setTimeout(() => {
                this.show = false;
            }, 1500);
        },
        scrollTop: function () {
            this.$core.scrollToElement('body');
        },
        triggerMenu: function () {
            if (this.$store.state.device === 'mob') {
                this.$store.state.backdrop = !this.$store.state.backdrop
                this.$store.state.mobileMenu = !this.$store.state.mobileMenu
            } else {
                this.$store.state.desktopMenu = !this.$store.state.desktopMenu
            }
        },
        triggerProfile: function () {
            this.$store.state.backdrop = !this.$store.state.backdrop
            this.$store.state.profileMenu = !this.$store.state.profileMenu
        },
        triggerCatalogSearchMobile: function () {
            this.$store.state.backdrop = !this.$store.state.backdrop
            this.$store.state.catalogMobileSearch = !this.$store.state.catalogMobileSearch
        },
        triggerAviaSearchMobile: function () {
            this.$store.state.backdrop = !this.$store.state.backdrop
            this.$store.state.avia.searchMobile = !this.$store.state.avia.searchMobile
        },
        logout: function () {
            this.$store.dispatch('logout');
            if (this.$store.state.device === 'mob') {
                this.triggerProfile();
            } else {
                this.$router.push('/');
            }
        },
        handleClick: function (e) {
            let $this = this;
            let classNames = this.$core.getPath(e);

            if (!classNames.includes('menu') && !classNames.includes('toggle-menu')) {
                $this.$store.state.desktopMenu = false;
            }

            if (!classNames.includes('search-room') && !classNames.includes('select-placement')) {
                $this.$store.state.selectPlacement = false;
            }

            if (!classNames.includes('search-result')) {
                $this.$store.state.searchTrigger = false;
            }

            if (!classNames.includes('catalog-search-mobile')
                && !classNames.includes('catalog-mobile-search')
                && !classNames.includes('layer-header')
                && !classNames.includes('layer-mobile')) {
                this.$store.state.catalogMobileSearch = false
                this.$store.state.avia.searchMobile = false
            }

            if (classNames.includes('modal-backdrop') || (classNames.includes('modal') && !classNames.includes('modal-dialog'))) {
                $this.$store.dispatch('closeWindows');
                $this.$store.dispatch('closeModals');
                $this.$store.state.backdrop = !$this.$store.state.backdrop;
            }
        },
        verifyDevice: function () {
            if (this.$store.state.width <= 992) {
                this.$store.state.device = 'mob';
            } else {
                this.$store.state.device = 'web';
            }
        },
    }
}
</script>
