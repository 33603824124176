// подключенные модули
import Vue from 'vue';
import App from './App.vue';
import VueNotification from 'vue-notification';
import router from './router';
import store from './store/store';
import axios from 'axios';
import Moment from 'moment';
import VueWaypoint from 'vue-waypoint';
import VueViewer from 'v-viewer';
import * as core from './assets/js/core';
import * as search from './assets/js/search';
import * as promocode from './assets/js/promocode';
import lodash from 'lodash';
import Multiselect from 'vue-multiselect'
import 'animate.css';
import VueClipboard from 'vue-clipboard2'

Vue.use(VueClipboard)
Vue.component('multiselect', Multiselect);
Vue.use(lodash);
Vue.use(VueViewer);
Vue.use(VueWaypoint);
Vue.use(VueNotification);

// import components
require('../src/components/slider/slider')

// подключенные стили
require('../src/assets/css/style.css');

Moment.locale('ru');
Vue.prototype.$moment = Moment;
Vue.prototype.$core = core;
Vue.prototype.$search = search;
Vue.prototype.$promocode = promocode;
Vue.prototype.$http = axios;

// default url for production version
Vue.config.productionTip = true;

if (Vue.config.productionTip) {
    axios.defaults.baseURL = 'https://apiv2.alamat.ru/api/v1';
    store.state.baseURL = 'https://apiv2.alamat.ru/api/v1';
} else {
    axios.defaults.baseURL = 'http://25.20.142.44/api/v1';
    store.state.baseURL = 'http://25.20.142.44/api/v1';
}

// token for auth

axios.interceptors.request.use(
    (config) => {
        let token = localStorage.getItem('token');

        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }

        config.headers['Content-Type'] = 'application/json'
        config.headers['Accept'] = 'application/json'

        return config;
    },

    (error) => {
        return Promise.reject(error);
    }
);

axios.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (error.response.status === 401) {
        store.dispatch('logout')
    }
    return Promise.reject(error);
});

new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app');
