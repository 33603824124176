import axios from 'axios'
export default {
    state: {
        phone: null,
        adults: [],
        children: [],
    },
    getters: {
        getAdultsConfirmation: state => state.adults,
        getChildrenConfirmation: state => state.children,
    },
    actions: {
        booking(ctx, data) {
            return new Promise((resolve, reject) => {
                axios.post('/booking', data).then(response => {
                    resolve(response);
                }).catch(error => {
                    reject(error);
                });
            });
        },
        verifyPromocode(ctx, code) {
            return new Promise((resolve, reject) => {
                axios.post('/promo-code/verify', {promocode: code}).then(response => {
                    resolve(response);
                }).catch(error => {
                    reject(error);
                });
            });
        },
        setPeopleHistory(ctx, data) {
            ctx.commit('clearHistory');
            ctx.commit('savePeopleHistory', data);
        }
    },
    mutations: {
        clearHistory(state) {
            state.adults = [];
            state.children = [];
        },
        savePeopleHistory(state, data) {
            state.adults = data.adults;
            state.children = data.children;
        }
    }
}
