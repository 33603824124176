import axios from 'axios'
import Vue from 'vue';
import qs from "qs";

export default {
    state: {
        user: {
            name: '',
            phone: null,
            last_name: '',
            middle_name: '',
            email: null,
            gender: 0,
            birth_date: '',
            referral_code: '',
            adv_messages: false,
            photo: null,
            orders: [],
            scores: 0,
            rating: [],
            notebooks: [],
            cards: [],
            totalCountOrder: 0,
        },
        favourites: []
    },
    getters: {
        userInfo: state => state.user,
        userPhone: state => state.user.phone,
        userEmail: state => state.user.email,
        userFavourites: state => state.favourites
    },
    actions: {
        getUserInfo(ctx) {
            return new Promise((resolve, reject) => {
                axios.get('/user').then(response => {
                    ctx.commit('setUserInfo', response);
                    resolve(response);
                }).catch(error => {
                    reject(error);
                });
            });
        },
        getUserOrders(ctx, data) {
            return new Promise((resolve, reject) => {
                axios.get('/user/orders?' + qs.stringify(data)).then(response => {
                    ctx.commit('setUserOrders', response);
                    resolve(response);
                }).catch(error => {
                    reject(error);
                });
            });
        },
        createNotebook(ctx, data) {
            return new Promise((resolve, reject) => {
                axios.post('user/notebook', data).then(response => {
                    resolve(response);
                }).catch(error => {
                    reject(error);
                });
            });
        },
        getNotebook(ctx) {
            return new Promise((resolve, reject) => {
                axios.get('user/notebook').then(response => {
                    ctx.commit('setNotebook', response);
                    resolve();
                }).catch(error => {
                    reject(error);
                });
            });
        },
        deleteNotebook(ctx, id) {
            return new Promise((resolve, reject) => {
                axios.delete('user/notebook/' + id).then(response => {
                    resolve(response);
                }).catch(error => {
                    reject(error);
                });
            });
        },
        updateUserInfo(ctx, data) {
            return new Promise((resolve) => {
                axios.patch('/user', data).then(response => {
                    resolve(response);
                }).catch(error => {
                    Vue.$toast.error(error.response.data.message)
                    //reject(error);
                });
            });
        },
        updateUserImage(ctx, data) {
            let formData = new FormData();
            formData.append('file', data);
            return new Promise((resolve, reject) => {
                axios.post('/user/photo',
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                ).then(response => {
                    resolve(response);
                }).catch(error => {
                    reject(error);
                });
            });
        },
        getUserFavourites(ctx) {
            return new Promise((resolve, reject) => {
                axios.get('/user/favorites/hotels').then(response => {
                    resolve(response);
                    ctx.commit('setFavourites', response.data.data.hotels)
                }).catch(error => {
                    reject(error);
                });
            });
        },
    },
    mutations: {
        setUserInfo(state, data) {
            state.user.name = data.data.data.name;
            state.user.phone = data.data.data.phone;
            state.user.last_name = data.data.data.last_name;
            state.user.middle_name = data.data.data.middle_name;
            state.user.email = data.data.data.email;
            state.user.referral_code = data.data.data.referral_code;
            state.user.gender = data.data.data.gender;
            state.user.adv_messages = data.data.data.adv_messages;
            state.user.birth_date = data.data.data.birth_date;
            state.user.photo = data.data.data.photo;
            state.user.scores = data.data.data.scores;
            // state.user.rating = data.data.data.rating;
             state.user.cards = data.data.data.cards;
        },
        setUserOrders(state, data) {
            state.user.orders = data.data.data.orders;
            state.user.totalCountOrder = data.data.data.totalCount;
        },
        setNotebook(state, data) {
            state.user.notebooks = data.data.data.contacts;
        },
        setFavourites(state, data) {
            state.favourites = data
        }
    }
}
