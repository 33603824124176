import axios from 'axios'
import qs from "qs";

export default {
    state: {
        selectOrder: [],
        reviews: [],
        reviewCount: 0,
    },
    getters: {
        getReviews: state => state.reviews,
        getReviewsCount: state => state.reviewCount
    },
    actions: {
        getHotelInfo(ctx, data) {
            return new Promise((resolve, reject) => {
                axios.get('/hotel/search/' + data.id,{
                    params: data,
                    paramsSerializer: params => {
                        return qs.stringify(params, {arrayFormat: 'brackets'})
                    }
                }).then(response => {
                    window.history.replaceState(
                        {},
                        null,
                        '/hotel/' + data.id + '?' + qs.stringify(data), {
                            arrayFormat: 'brackets'
                        })
                    resolve(response);
                }).catch(error => {
                    reject(error);
                });
            });
        },
        getHotelRoomInfo(ctx, data) {
            return new Promise((resolve, reject) => {
                axios.get('/hotel/' + data.hotelId + '?roomId=' + data.roomId
                ).then(response => {
                    resolve(response);
                }).catch(error => {
                    reject(error);
                });
            });
        },
        getHotelAviaInfo(ctx, data) {
            return new Promise((resolve, reject) => {
                axios.get('avia/search?from=' + data.from
                    + '&to=' + data.to
                    + '&date=' + data.date
                    + '&dateBack=' + data.dateBack
                    + '&adults=' + data.adults
                    + '&children=' + data.children
                    + '&isBack=' + data.isBack
                ).then(response => {
                    resolve(response);
                }).catch(error => {
                    reject(error);
                });
            });
        },
        getRoomInfoByHash(ctx, data) {
            return new Promise((resolve) => {
                axios.get('property/' + data.propertyId + '/room?arrivalDate=' + data.dateStart + '&departureDate=' + data.dateEnd + '&adults=' + data.adults + '&children=' + data.children + '&hash=' + data.hash
                ).then(response => {
                    resolve(response);
                }).catch(error => {
                    console.log(error)
                });
            });
        },
        getReviews(ctx, data) {
            return new Promise((resolve) => {
                axios.get('/hotel/' + data.id + '/reviews?' + qs.stringify(data.filter)
                ).then(response => {
                    ctx.commit('setReviews', response.data.data)
                    resolve(response);
                }).catch(error => {
                    console.log(error)
                });
            });
        },
        getCalculateCancellationPenalty(ctx, data) {
            console.log(data)
            return new Promise((resolve) => {
                axios.get('/booking/' + data.orderId + '/cancellation-penalty'
                ).then(response => {
                    resolve(response);
                }).catch(error => {
                    console.log(error)
                });
            });
        },
        cancelOrder(ctx, data) {
            return new Promise((resolve) => {
                axios.post('/booking/cancel', data
                ).then(response => {
                    resolve(response);
                }).catch(error => {
                    console.log(error)
                });
            });
        },
    },
    mutations: {
        setReviews(state, data) {
            state.reviews = data.reviews
            state.reviewCount = data.totalCount
        }
    }
}
