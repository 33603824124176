import moment from "moment";

export function buildSearch(data) {
    let newData = {
        from: null,
        text: null,
        type: null,
        dateStart: null,
        dateEnd: null,
        adults: null,
        children: null,
        childrenAges: '',
    };

    let agesArr = [];
    let agesString = '';

    if (data.childrenAges.length > 0) {
        Object.keys(data.childrenAges).forEach((key) => {
            agesArr.push(`childrenAges[]=` + data.childrenAges[key].code);
        });
        agesString = agesArr.join('&');
    }

    newData.from = data.from;
    newData.text = data.text;
    newData.type = data.type;
    newData.dateStart = data.date.length > 0 ? data.date[0] : moment().format('yyyy-MM-DD');
    newData.dateEnd = data.date.length > 0 ? data.date[1] : moment().add(1, 'days').format('yyyy-MM-DD');
    newData.adults = data.adults;
    newData.children = data.children;
    newData.childrenAges = agesString;

    return newData;
}
