<template>
  <div class="error-booking modal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
        </div>
        <div class="modal-body">
          <svg width="79" height="78" viewBox="0 0 79 78" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M48.163 38.9945L76.09 11.1324C77.3129 9.90946 78 8.25076 78 6.52122C78 4.79169 77.3129 3.13299 76.09 1.91002C74.867 0.687056 73.2083 0 71.4788 0C69.7492 0 68.0906 0.687056 66.8676 1.91002L39.0055 29.837L11.1435 1.91002C9.92054 0.687056 8.26184 -1.2886e-08 6.53231 0C4.80277 1.2886e-08 3.14407 0.687056 1.92111 1.91002C0.698139 3.13299 0.0110838 4.79169 0.0110838 6.52122C0.0110838 8.25076 0.698139 9.90946 1.92111 11.1324L29.8481 38.9945L1.92111 66.8565C1.31237 67.4603 0.82921 68.1786 0.499485 68.97C0.16976 69.7614 0 70.6103 0 71.4677C0 72.3251 0.16976 73.174 0.499485 73.9654C0.82921 74.7568 1.31237 75.4751 1.92111 76.0789C2.52487 76.6876 3.24318 77.1708 4.03462 77.5005C4.82605 77.8302 5.67493 78 6.53231 78C7.38968 78 8.23856 77.8302 9.03 77.5005C9.82143 77.1708 10.5397 76.6876 11.1435 76.0789L39.0055 48.1519L66.8676 76.0789C67.4714 76.6876 68.1897 77.1708 68.9811 77.5005C69.7725 77.8302 70.6214 78 71.4788 78C72.3362 78 73.185 77.8302 73.9765 77.5005C74.7679 77.1708 75.4862 76.6876 76.09 76.0789C76.6987 75.4751 77.1819 74.7568 77.5116 73.9654C77.8413 73.174 78.0111 72.3251 78.0111 71.4677C78.0111 70.6103 77.8413 69.7614 77.5116 68.97C77.1819 68.1786 76.6987 67.4603 76.09 66.8565L48.163 38.9945Z"
                fill="#FF5252"/>
          </svg>

          <h1>Данные устарели</h1>
          <p>Перенаправляем вас на страницу отеля, возможно изменилась цена или эти даты уже заняты</p>

        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'error-booking-modal',
  data: function () {
    return {
    }
  },
  async mounted() {

  },
  methods: {
  }
}
</script>

<style scoped>
.error-booking .modal-dialog .modal-body h1 {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  margin-top: 30px;
}
.error-booking .modal-dialog .modal-body p {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  max-width: 335px;
  margin: 30px 0 30px 0;
  text-align: center;
}
</style>
