import axios from 'axios'
import qs from 'qs'

export default {
    state: {},
    getters: {},
    actions: {
        // Автокомплит регионы и отели
        getAutoCompleteRegionAndHotel(ctx, data) {
            return new Promise((resolve, reject) => {
                axios.get('/hotel/search/autocomplete?' + qs.stringify(data)).then(response => {
                    resolve(response);
                }).catch(error => {
                    reject(error);
                });
            });
        },
        // Поиск отелей по региону
        getVariantsByRegion(ctx, data) {
            return new Promise((resolve, reject) => {
                axios.get('/hotel/search/region/' + data.id, {
                    params: data,
                    paramsSerializer: params => {
                        return qs.stringify(params, {arrayFormat: 'brackets'})
                    }
                }).then(response => {
                    resolve(response);
                }).catch(error => {
                    reject(error);
                });
            });
        },
    },
    mutations: {}
}
