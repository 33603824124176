<template>
  <div class="cancel-order-modal modal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <div class="close pointer" @click="close">
            <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g filter="url(#filter0_b_631_2916)">
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M13 26C20.1797 26 26 20.1797 26 13C26 5.8203 20.1797 0 13 0C5.8203 0 0 5.8203 0 13C0 20.1797 5.8203 26 13 26Z"
                      fill="#F5F5F5"/>
              </g>
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M17.8754 8.34029C17.466 7.93081 16.8065 7.9264 16.4024 8.33045L12.9336 11.7993L9.54098 8.40673C9.1315 7.99725 8.46754 7.99731 8.05799 8.40686C7.64844 8.81641 7.64838 9.48036 8.05787 9.88984L11.4504 13.2824L8.1146 16.6183C7.71056 17.0223 7.71496 17.6818 8.12445 18.0913C8.53393 18.5008 9.19342 18.5052 9.59747 18.1011L12.9333 14.7653L16.326 18.158C16.7355 18.5675 17.3994 18.5674 17.809 18.1579C18.2185 17.7483 18.2186 17.0844 17.8091 16.6749L14.4164 13.2822L17.8853 9.81331C18.2893 9.40927 18.2849 8.74978 17.8754 8.34029Z"
                    fill="black"/>
              <defs>
                <filter id="filter0_b_631_2916" x="-45.7896" y="-45.7896" width="117.579" height="117.579"
                        filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                  <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                  <feGaussianBlur in="BackgroundImage" stdDeviation="22.8948"/>
                  <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_631_2916"/>
                  <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_631_2916" result="shape"/>
                </filter>
              </defs>
            </svg>
          </div>
        </div>
        <div class="modal-body" v-if="!load">
          <svg width="79" height="78" viewBox="0 0 79 78" fill="none" xmlns="http://www.w3.org/2000/svg"
               v-if="this.penalty > 0">
            <path
                d="M48.163 38.9945L76.09 11.1324C77.3129 9.90946 78 8.25076 78 6.52122C78 4.79169 77.3129 3.13299 76.09 1.91002C74.867 0.687056 73.2083 0 71.4788 0C69.7492 0 68.0906 0.687056 66.8676 1.91002L39.0055 29.837L11.1435 1.91002C9.92054 0.687056 8.26184 -1.2886e-08 6.53231 0C4.80277 1.2886e-08 3.14407 0.687056 1.92111 1.91002C0.698139 3.13299 0.0110838 4.79169 0.0110838 6.52122C0.0110838 8.25076 0.698139 9.90946 1.92111 11.1324L29.8481 38.9945L1.92111 66.8565C1.31237 67.4603 0.82921 68.1786 0.499485 68.97C0.16976 69.7614 0 70.6103 0 71.4677C0 72.3251 0.16976 73.174 0.499485 73.9654C0.82921 74.7568 1.31237 75.4751 1.92111 76.0789C2.52487 76.6876 3.24318 77.1708 4.03462 77.5005C4.82605 77.8302 5.67493 78 6.53231 78C7.38968 78 8.23856 77.8302 9.03 77.5005C9.82143 77.1708 10.5397 76.6876 11.1435 76.0789L39.0055 48.1519L66.8676 76.0789C67.4714 76.6876 68.1897 77.1708 68.9811 77.5005C69.7725 77.8302 70.6214 78 71.4788 78C72.3362 78 73.185 77.8302 73.9765 77.5005C74.7679 77.1708 75.4862 76.6876 76.09 76.0789C76.6987 75.4751 77.1819 74.7568 77.5116 73.9654C77.8413 73.174 78.0111 72.3251 78.0111 71.4677C78.0111 70.6103 77.8413 69.7614 77.5116 68.97C77.1819 68.1786 76.6987 67.4603 76.09 66.8565L48.163 38.9945Z"
                fill="#FF5252"/>
          </svg>
          <svg width="97" height="75" viewBox="0 0 97 75" fill="none" xmlns="http://www.w3.org/2000/svg" v-else>
            <path d="M5 37.5L37.625 70L92 5" stroke="#FED42B" stroke-width="10" stroke-linecap="round"
                  stroke-linejoin="round"/>
          </svg>
          <h1> {{ cancelledText }}</h1>
          <p>Вы точно хотите отменить бронирование?</p>
          <div class="buttons">
            <button class="btn" :class="this.penalty > 0 ? 'btn-attention': 'btn-info'" @click="startCancel">Да
            </button>
            <button class="btn btn-disabled" @click="close">Нет</button>
          </div>
        </div>
        <loader v-else/>
      </div>
    </div>
  </div>
</template>

<script>

import Loader from "@/components/loaders/loader";

export default {
  name: 'cancel-order-modal',
  components: {Loader},
  data: function () {
    return {
      data: {
        orderId: 0,
      },
      penalty: 0,
      load: true,
    }
  },
  async mounted() {
    this.data.orderId = this.$store.state.selectOrder.id
    this.getPenaltyInfo();
  },
  computed: {
    cancelledText: function () {
      if (this.$store.state.selectOrder.amount === this.penalty) {
        return 'Деньги за отмену не возвращаются';
      } else if (this.$store.state.selectOrder.amount > this.penalty) {
        return 'Отмена с удержанием ' + this.penalty + ' руб.';
      } else {
        return 'Бесплатная отмена';
      }
    },
  },
  methods: {
    startCancel: function () {
      this.$store.dispatch('cancelOrder', this.data).then((res) => {
        if (res.data.result === 'ok') {
          this.$root.$emit('getUserOrders');
          this.close();
          this.$root.$emit('openModal', 'successCancelOrder')
        }
      })
    },
    getPenaltyInfo: function () {
      this.$store.dispatch('getCalculateCancellationPenalty', this.data).then((data) => {
        this.penalty = data.data.data.penaltyAmount;
        this.load = false;
      });
    },
    close: function () {
      this.$root.$emit('openModal', 'cancelOrder');
    },
  }
}
</script>

<style scoped>
.cancel-order-modal .modal-dialog .modal-body h1 {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  margin-top: 30px;
}

.cancel-order-modal .modal-dialog .modal-body p {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  max-width: 335px;
  margin: 30px 0 30px 0;
  text-align: center;
}

.buttons {
  display: flex;
  justify-content: space-between;
  width: 335px;
  padding-bottom: 20px;
}

.buttons .btn {
  width: 160px;
  height: 50px;
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
  color: #000000;
}

</style>
