<template>
  <div
      class="input-loader"
      v-if="loading"
      :style="{top, right}"/>
</template>

<script>
export default {
  name: 'InputLoader',
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    cssTop: {
      type: Number,
      default: 24
    },
    cssRight: {
      type: Number,
      default: 10
    },
  },
  data: function () {
    return {}
  },
  computed: {
    top: function () {
      return this.cssTop + 'px'
    },
    right: function () {
      return this.cssRight + 'px'
    },
  },
  async mounted() {
  },
  methods: {}
}
</script>

<style scoped>
.input-loader {
  width: 16px;
  height: 16px;
  border: 2px solid #004ED8;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  position: absolute;
  top: 24px;
  right: 10px;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
